import React from 'react';
import {
    MaterialReactTable,
    createMRTColumnHelper,
    useMaterialReactTable,
  } from 'material-react-table';
  import { Box, Button } from '@mui/material';
  import FileDownloadIcon from '@mui/icons-material/FileDownload';
  import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

  import { jsPDF } from 'jspdf'; 
  import autoTable from 'jspdf-autotable';
  import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { FcApproval } from "react-icons/fc";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import {api} from'../api'
import BookingCreate from './Booking';
import PaymentUpdate from './Payment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


  
  const PaymentReport = () => {
    
    const [data,setData]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [editPayment,setPayment]=useState();



    const handleEdit = (rowData) => {
    //  console.log(rowData);
     setPayment(rowData);
    };
   


    const paymentData=async()=>{
      const apiData=await axios.get(`${api.api}/booking/bookingReports`);
      // console.log(apiData.data.booking);
      const value=apiData.data.booking;
      const database=value.map(item=>{
        return ({item,'payment':item?.payments[0] ||[]});
      })
      setData(database);
      // console.log(database);
    }

    const columnHelper = createMRTColumnHelper();
  
    const columns = [
          // columnHelper.accessor('item.booking_id', {
          //   header: 'Invoice No',
          //   size: 40,
          // }),
          columnHelper.accessor('item.customer.customer_name', {
            header: 'Customer Name',
            size: 40,
          }),
          columnHelper.accessor('item.customer.phone_number', {
            header: 'MobileNo',
            size: 40,
          }),
          columnHelper.accessor('item.vehicle.vehicle_number', {
            header: 'Taxi No',
            size: 40,
          }),
          // columnHelper.accessor('item.driver.driver_name', {
          //   header: 'Driver Name',
          //   size: 40,
          // }),
          // columnHelper.accessor('item.driver.phone_number', {
          //   header: 'MobileNo',
          //   size: 40,
          // }),
          columnHelper.accessor('item.pickup_location', {
            header: 'Pickup Location',
            size: 40,
          }),
          columnHelper.accessor('item.dropoff_location', {
            header: 'Drop Location',
            size: 40,
          }),
          columnHelper.accessor('item.bill_amount', {
            header: 'Bill Amount',
            size: 40,
          }),
          columnHelper.accessor('item.advance', {
            header: 'Advance',
            size: 40,
          }),
          columnHelper.accessor('payment.our_amount', {
            header: 'Paid Amount',
            size: 40,
          }),
          columnHelper.accessor('payment.to_be_paid', {
            header: 'Un Paid',
            size: 40,
          }),
          columnHelper.accessor('item.city',{
            accessor: 'action',
            header: 'Status',
            size: 40,
            Cell:({row},rowIndex) => {
              return(
                <Box>
                <div>
                {row.original.payment.to_be_paid == 0 ?<h6>Paid</h6>:<h6>Un Paid</h6>}
                </div>
              </Box>)
              }
            }),
          columnHelper.accessor('item.city',{
            accessor: 'action',
            header: 'Update',
            size: 160,
            Cell:({row},rowIndex) => {
              // console.log(row);
              return(
                <Box>
               <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title={row.original.payment.to_be_paid == 0 ?"Already Paid":"Payment"}>
               {row.original.payment.to_be_paid == 0 ?<FcApproval className='fs-6'/>:<EditIcon className='fs-6' onClick={() => handleEdit(row.original)} />}
                </IconButton>
              </Box>)
              }
            }),
        ];
          
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map(n => {
      return n
    });
    const item=value.map(single=>{
      return ({
        "Invoice No":single.item.booking_id,
        "Customer Name ":single.item.customer.customer_name,
        "Customer Mobile No ":single.item.customer.phone_number,
        "Customer Address ":single.item.customer.address,
        "Vehicle No":single.item.vehicle.vehicle_number,
        "Vehicle Type":single.item.vehicle.vehicle_type,
        "Driver Name ":single.item.driver.driver_name,
        "Driver Mobile No ":single.item.driver.phone_number,
        "Booking Date & Time":new Date (single.item.booking_date).toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }),
        // "Booking Time":new Date (single.item.booking_time).toLocaleDateString('en-GB'),
        "Drop Date & Time":new Date(single.item.dropping_time).toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }),
        "Pickup Location":single.item.pickup_location,
        "Drop Location":single.item.dropoff_location,
        "Invoice Amount":single.item.bill_amount,
        "Advance Payment Type":single.item.payment_mode,
        "Advance Amount":single.item.advance,
        "Customer Bill Payment Type ":single.payment.payment_mode,
        "Customer Paid Amount ":single.payment.our_amount,
        "Customer Outstanding Amount ":single.payment.to_be_paid,
      })
    })
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };


  const handleExportData = () => {
    const value = data.map(n => {
  return n
});

const item=value.map(single=>{
  return ({
    "Invoice No":single.item.booking_id,
    "Customer Name ":single.item.customer.customer_name,
    "Customer Mobile No ":single.item.customer.phone_number,
    "Customer Address ":single.item.customer.address,
    "Vehicle No":single.item.vehicle.vehicle_number,
    "Vehicle Type":single.item.vehicle.vehicle_type,
    "Driver Name ":single.item.driver.driver_name,
    "Driver Mobile No ":single.item.driver.phone_number,
    "Booking Date & Time":new Date (single.item.booking_date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
    // "Booking Time":new Date (single.item.booking_time).toLocaleDateString('en-GB'),
    "Drop Date & Time":new Date(single.item.dropping_time).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
    "Pickup Location":single.item.pickup_location,
    "Drop Location":single.item.dropoff_location,
    "Invoice Amount":single.item.bill_amount,
    "Advance Payment Type":single.item.payment_mode,
    "Advance Amount":single.item.advance,
    "Customer Bill Payment Type ":single.payment.payment_mode,
    "Customer Paid Amount ":single.payment.our_amount,
    "Customer Outstanding Amount ":single.payment.to_be_paid,
  })
})
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);

  };



    const handleExportRowsPDF = (rows) => {
      const rowData = rows.map((row) => row.original);
      const value = rowData.map(n => {
        return n
      });

      const item=value.map(single=>{
        return ({
          "Invoice No":single.item.booking_id,
          "Customer Name ":single.item.customer.customer_name,
          "Customer Mobile No ":single.item.customer.phone_number,
          "Customer Address ":single.item.customer.address,
          "Vehicle No":single.item.vehicle.vehicle_number,
          "Vehicle Type":single.item.vehicle.vehicle_type,
          "Driver Name ":single.item.driver.driver_name,
          "Driver Mobile No ":single.item.driver.phone_number,
          "Booking Date & Time":new Date (single.item.booking_date).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }),
          "Drop Date & Time":new Date(single.item.dropping_time).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }),
          "Pickup Location":single.item.pickup_location,
          "Drop Location":single.item.dropoff_location,
          "Invoice Amount":single.item.bill_amount,
          "Advance Payment Type":single.item.payment_mode,
          "Advance Amount":single.item.advance,
          "Customer Bill Payment Type ":single.payment.payment_mode,
          "Customer Paid Amount ":single.payment.our_amount,
          "Customer Outstanding Amount ":single.payment.to_be_paid,
        })
      })
      
      // const doc = new jsPDF();
      const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape
        format: [200, 500],
      });
      const tableData = item.map((row) => Object.values(row));
      const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
      // console.log(tableHeaders);
      doc.save('Payment_Report.pdf');
    };

    const handleExportAllRowsPDF = (rows) => {
      const rowData = data.map((row) => row);
      // console.log(rowData);
      const value = rowData.map(n => {
        return n
      });
    
  const item=value.map(single=>{
      return ({
        "Invoice No":single.item.booking_id,
        "Customer Name ":single.item.customer.customer_name,
        "Customer Mobile No ":single.item.customer.phone_number,
        "Customer Address ":single.item.customer.address,
        "Vehicle No":single.item.vehicle.vehicle_number,
        "Vehicle Type":single.item.vehicle.vehicle_type,
        "Driver Name ":single.item.driver.driver_name,
        "Driver Mobile No ":single.item.driver.phone_number,
        "Booking Date & Time":new Date (single.item.booking_date).toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }),
        // "Booking Time":new Date (single.item.booking_time).toLocaleDateString('en-GB'),
        "Drop Date & Time":new Date(single.item.dropping_time).toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }),
        "Pickup Location":single.item.pickup_location,
        "Drop Location":single.item.dropoff_location,
        "Invoice Amount":single.item.bill_amount,
        "Advance Payment Type":single.item.payment_mode,
        "Advance Amount":single.item.advance,
        "Customer Bill Payment Type ":single.payment.payment_mode,
        "Customer Paid Amount ":single.payment.our_amount,
        "Customer Outstanding Amount ":single.payment.to_be_paid,
      })
    })
      
      // const doc = new jsPDF();
      const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape
        format: [200, 500],
      });
      const tableData = item.map((row) => Object.values(row));
      const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
      // console.log(tableHeaders);
      doc.save('Payment_Report.pdf');
    };
  
    const table = useMaterialReactTable({
      columns,
      data,
      enableRowNumbers: true,
      rowNumberDisplayMode: 'original',
      enableFullScreenToggle: false,
      // enableRowSelection: true,
      columnFilterDisplayMode: 'popover',
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      enableGlobalFilter: false,
      enableStickyHeader: true,
      enableGlobalFilter: false,
      initialState: { pagination: { pageSize: 20, pageIndex: 0, }, density: 'compact' },
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
         <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
            All
          </Button>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
            Filter
          </Button>
          <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportAllRowsPDF()
          }
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
         All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
        Filter
        </Button>
        </Box>
      ),
    });
  

    useEffect(()=>{
      paymentData();
    },[]);


    const navigate = useNavigate();
    useEffect(()=>{
      paymentData();
      if(editPayment == undefined){
        navigate('/home/PaymentReport')
      }
    },[editPayment]);

    // console.log(editPayment);
    

    return( 
      <div>
       {editPayment?.item?.booking_id>0?<div><div className='d-flex col-12 justify-content-end'><button className='btn btn-primary col-2'onClick={()=>{setPayment()}} >Back</button></div><PaymentUpdate paymentDetails={editPayment} setEditPayment={setPayment} back={setPayment}/> </div>:<div><h1 className='text-center'>Payment</h1><MaterialReactTable table={table} /></div>}
        </div>
    );
  };
  
  export default PaymentReport;
  