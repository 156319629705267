import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {api} from'../api'
import { Link, useNavigate, useParams } from 'react-router-dom';

const CustomerCreation=({customerDetail,back})=>{
  const [customer,setCustomer]=useState([{customerName:"",customerMobile:"",gst:"",corporate:"",customerAddress:"",CustomerCity:""}]);
  const[error,setError]=useState({name:"",mobile:"",address:"",city:""});
  const [checkNav,setCheckNav]=useState();
  


  const newCustomer=async()=>{
    if(customerDetail?.customer_id >0){

      const customerName=customer[0].customerName;
    const customerMobile=customer[0].customerMobile;
    const customerAddress=customer[0].customerAddress;
    const CustomerCity=customer[0].CustomerCity;
    const gst_no=customer[0].gst;
    const corporate=customer[0].corporate;
    const id=customerDetail?.customer_id;
    function isValidMobileNumber(customerMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(customerMobile);
    }
    if (isValidMobileNumber(customerMobile)) {
    if(customerName.trim().length >=3 && customerMobile.trim().length == 10 && customerAddress.trim().length>=3 && CustomerCity.trim().length>=3){

      const createCustomer=await axios.post(`${api.api}/customer/updateCustomer`,{customerName,customerMobile,customerAddress,CustomerCity,id,gst_no,corporate});
      // console.log(createCustomer);
      alert("Update Successfully");
      navigate('/home/customerReport');
      back();
      setCustomer([{customerName:"",customerMobile:"",customerAddress:"",CustomerCity:"",gst:"",corporate:""}]);
    }else{
      alert("Please Enter Valid Details")
      validation()
    }
  }else{ 
    alert("Please Enter Valid Mobile No")
    validation()
  }

    }else{
      try{
    const customerName=customer[0].customerName;
    const customerMobile=customer[0].customerMobile;
    const customerAddress=customer[0].customerAddress;
    const CustomerCity=customer[0].CustomerCity;
    const gst_no=customer[0].gst;
    const corporate=customer[0].corporate;
    function isValidMobileNumber(customerMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(customerMobile);
    }
    if(customerName.trim().length>=3){
      if (isValidMobileNumber(customerMobile)){
    if(customerMobile.trim().length == 10){
     if(customerAddress.trim().length>=3){
      if(CustomerCity.trim().length>=3){
    const createCustomer=await axios.post(`${api.api}/customer/createCustomer`,{customerName,customerMobile,customerAddress,CustomerCity,gst_no,corporate});
    setCustomer([{customerName:"",customerMobile:"",customerAddress:"",CustomerCity:"",gst:"",corporate:""}]);
    // console.log(createCustomer);
    alert("Success");
    navigate('/home/customerReport');
    }else{
      // setError("City");
      validation()
    }
  }else{
    // setError("address")
    validation()
  }
  }else{
    // setError("mobile")
    validation()
  }
  }else{
    // setError("mobile");
    validation()
  }
}else{
    // setError("name")
    validation()
    }
}catch(err){
  alert("Already Existing Customer");
}
}
  }



  let navigate = useNavigate();
  const updateValue=()=>{
    if(customerDetail?.customer_id >0){
      navigate('/home/customerUpdate')
      let value=[...customer];
      value[0].customerName=customerDetail.customer_name;
      value[0].customerMobile=customerDetail.phone_number;
      value[0].customerAddress=customerDetail.address;
      value[0].CustomerCity=customerDetail.city;
      value[0].gst=customerDetail.gst_no?customerDetail.gst_no:"";
      value[0].corporate=customerDetail.corporate?customerDetail.corporate:"";
      setCustomer(value);
    }
  }


  const validation=()=>{
    const customerName=customer[0].customerName;
    const customerMobile=customer[0].customerMobile;
    const customerAddress=customer[0].customerAddress;
    const CustomerCity=customer[0].CustomerCity;
    function isValidMobileNumber(customerMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(customerMobile);
    }

    let value={...error};
    if (!(customerName.trim().length >= 3)) {
  value.name="name";
}else{
value.name="";
  }
if (!isValidMobileNumber(customerMobile)){
  value.mobile="mobile";
}else if(customerMobile.trim().length !== 10){
  value.mobile="mobile";
}else{
  value.mobile="";
}


if(customerAddress.trim().length>=3){
  value.address="";
}else{
  value.address="address";
}
if(CustomerCity.trim().length>=3){
  value.city="";
}else{
  value.city="City";
}

setError(value);
}

// console.log(error)

  useEffect(() => {
    if (window.location.pathname === "/home/customerReport") {
      // back();
      // console.log("One");
    }else if(window.location.pathname === "/home/customerReport"){
      // console.log("two");
    }
  }, []); 


  
  
  useEffect(()=>{
    updateValue();
    // console.log("work");
  },[customerDetail])


  const value=localStorage.getItem('taxinow')?localStorage.getItem('taxinow'):""

  useEffect(()=>{
if(localStorage.getItem('taxinow')){

}else{
  navigate('/home/customerReport');
}
  },[value])

  // console.log(window.location.pathname=="/home/customerReport");
    return(
      <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body row">
          <h1 className="page-title text-center fs-1">{customerDetail?.customer_id?"Customer Update":"Customer Creation"}</h1>
          {/* <p className="card-description"> Basic form elements </p> */}

          <div className="forms-sample pt-2">
            <div className='row'>
            <div className="form-group col-md-6">
              <label for="exampleInputName1" className='text-black d-flex fs-5'>Name <span className="text-danger">*</span></label>
              <input type="text" className="form-control fs-6" id="exampleInputName1" value={customer[0].customerName} onChange={(e)=>{
                let value=[...customer]; value[0].customerName=e.target.value.replace(/[^A-Za-z ]/g, '');
                if (  value[0].customerName.length === 1 &&  value[0].customerName === ' ') {
                  value[0].customerName= '';
                }    
                 setCustomer(value) }}/>
              <p className='text-danger'>{error.name == "name"&&"Please Enter Valid Customer Name"}</p>
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputEmail3"  className='text-black fs-5'>Mobile No<span className="text-danger">*</span></label>
              <input type="text"  className="form-control fs-6" id="exampleInputEmail3" value={customer[0].customerMobile} onChange={(e)=>{let value=[...customer]; value[0].customerMobile=e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, ''); setCustomer(value) }} disabled={customerDetail?.customer_id} />
              <p className='text-danger'>{error.mobile == "mobile"&&"Please Enter Valid Mobile No"}</p>
            </div>
            </div>
            <div className='row'>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword4"  className='text-black fs-5'>GST No</label>
              <input type="text"  className="form-control fs-6" id="exampleInputPassword4" value={customer[0].gst} onChange={(e)=>{let value=[...customer]; value[0].gst=e.target.value.toUpperCase(); setCustomer(value) }} />
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword4"  className='text-black fs-5'>Corporate</label>
              <input type="text" className="form-control fs-6" id="exampleInputPassword4" value={customer[0].corporate} onChange={(e)=>{let value=[...customer]; value[0].corporate=e.target.value; setCustomer(value) }} />
            </div>
            </div>
            <div className='row'>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword4"  className='text-black fs-5'>Address <span className="text-danger">*</span></label>
              <input type="text"  className="form-control fs-6" id="exampleInputPassword4" value={customer[0].customerAddress} onChange={(e)=>{let value=[...customer]; value[0].customerAddress=e.target.value; setCustomer(value) }} />
              <p className='text-danger'>{error.address == "address"&&"Please Enter Valid Address"}</p>
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword4"  className='text-black fs-5'>City <span className="text-danger">*</span></label>
              <input type="text" className="form-control fs-6" id="exampleInputPassword4" value={customer[0].CustomerCity} onChange={(e)=>{let value=[...customer]; value[0].CustomerCity=e.target.value.replace(/[^A-Za-z]/g, ''); setCustomer(value) }} />
              <p className='text-danger'>{error.city == "City"&&"Please Enter Valid City"}</p>
            </div>
            </div>
            <div className='d-flex col-12  justify-content-center'>
           <span className='col-4'><button type="submit" onClick={()=>{newCustomer();}} className="btn btn-primary mr-2">Submit</button></span> 
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default CustomerCreation;