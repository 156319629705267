import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { api } from "../../api";
import Autocomplete from '@mui/material/Autocomplete';
import{ createFilterOptions } from '@mui/material/Autocomplete';
import { Link, useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { setHours, setMinutes } from 'date-fns';
import { setHours, setMinutes, getMinutes, getHours,format  } from 'date-fns';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { MdDelete } from "react-icons/md";


const Quotation = ({ billingDetails , setEditBilling,back}) => {
  const [billing, setBilling] = useState([
    {
      header: "",
      tripLocation: "",
      tripDays: "",
      tripKM: "",
      perKmAmount: "",
      perDayAmount: "",
      permit:"",
      Discount: "",
      advance: "",
      notes:"",
      gst:"without"
    },
  ]);

// console.log(billing)
  const[billingError,setBillingError]=useState([
    {
      header: "",
      tripLocation: "",
      tripDays: "",
      tripKM: "",
      perKmAmount: "",
      perDayAmount: "",
      permit:"",
      Discount: "",
      advance: "",
      notes:"",
    },
  ]);

  const [startDate, setStartDate] = useState(new Date());

  const navigate = useNavigate();

  const [driverList, setDriverList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [changeCustomerList, setChangeCustomerList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  // console.log(billingDetails);
// console.log(billing);

  const updateValue = () => {
    if (billingDetails?.item) {
      // navigate('/home/bookingUpdate')
      // console.log("SetValue");
      let value = [...billing];
      value[0].header= billingDetails?.item.header_notes;
      value[0].tripLocation= billingDetails?.item.location;
      value[0].tripDays= billingDetails?.item.trip_day;
      value[0].tripKM= billingDetails?.item.trip_km;
      value[0].perKmAmount= billingDetails?.item.per_km_amount;
      value[0].perDayAmount= billingDetails?.item.per_day_amount;
      value[0].permit= billingDetails?.item.permit;
      value[0].Discount= billingDetails?.item.discount;
      value[0].advance= billingDetails?.item.advance;
      value[0].notes= billingDetails?.item.notes;
      value[0].gst= billingDetails?.item.gst;
      setBilling(value);
    }

  };

  const fetchData = async () => {
    const database = await axios.get(`${api.api}/booking/allSubSelection`);
    // console.log(database.data);
    setDriverList(database?.data?.driver);
    setCustomerList(database?.data?.customer);
    setVehicleList(database?.data?.vehicle);
  };


  const bookingForm=async()=>{
    validation();
    const header= billing[0].header;
    const tripLocation=billing[0].tripLocation;
    const tripDays=billing[0].tripDays;
    const tripKM=billing[0].tripKM;
    const perKmAmount=billing[0].perKmAmount;
    const perDayAmount=billing[0].perDayAmount;
    const permit=billing[0].permit;
    const Discount=billing[0].Discount;
    const advance=billing[0].advance;
    const notes=billing[0].notes;
    const gst=billing[0].gst;
    const id=billingDetails?.item?.id;
  
    const dayRent=Number(tripDays?tripDays:1)*Number(perDayAmount?perDayAmount:1);
    const kmRent=Number(tripKM?tripKM:1)*Number(perKmAmount?perKmAmount:perKmAmount);
    const totalAmount=(Number(dayRent)+Number(kmRent))+(Number(permit?permit:0)-Number(Discount));
    const cgst=gst !== "without" ?Number(totalAmount)*6/100:0;
    // const sumAmount=Number(cgst)*2+Number(totalAmount);
    // console.log(sumAmount);
    // console.log(cgst,"tax")
    // console.log(totalAmount);
    // console.log(dayRent,"day rend")
    // console.log(kmRent,'km rent')
    // console.log(permit,'permit')
  
      if(tripLocation.length>=3 && Number(tripDays)>=1 && Number(tripKM)>=1&& Number(perKmAmount)>=1 && Number(perDayAmount)>=1 && Number(Discount)>=1 && Number(advance)>=1){
    if(!id){
   const bookTaxi=await axios.post(`${api.api}/quotation/createQuotation`,{header,tripLocation,tripDays,tripKM,perKmAmount,perDayAmount,permit,advance,notes,Discount,gst,cgst});
    alert("Successfully Created");
    navigate('/home/QuotationReport')
    }else if(id){
      const bookTaxi=await axios.post(`${api.api}/quotation/updateQuotation`,{header,tripLocation,tripDays,tripKM,perKmAmount,perDayAmount,permit,advance,notes,Discount,id,gst,cgst});
    alert("Successfully Updated");
    back("");
    }
  }else{
    alert("Please Give Valid Information")
  }
  }

  const handleDriver = (e, n) => {
    // console.log(n)
    if (n && n.driver_id) {
      // console.log(`Selected Driver ID: ${n.driver_id}`);
      let value = [...billing];
      value[0].driverName = n;
      setBilling(value);
    }else{
      let value = [...billing];
      value[0].driverName = "";
      setBilling(value);
    }
  };

  
  const handleCustomer = (e, n) => {

    //  console.log(n);
    //  console.log(`Selected Driver ID: ${n.customer_id}`);
    if (n && n.customer_id) {
      // console.log(n);
      // console.log(`Selected Driver ID: ${n.driver_id}`);
      let value = [...billing];
      value[0].customerName = n;
      setBilling(value);
    }
    else{
      let value = [...billing];
      value[0].customerName = "";
      setBilling(value);
     }
    // console.log(n);
  };

  const handleVehicle = (e, n) => {
    if (n && n.vehicle_id) {
      // console.log(n);
      // console.log(`Selected Driver ID: ${n.driver_id}`);
      let value = [...billing];
      value[0].vehicleNumber = n;
      setBilling(value);
    }else{
      let value = [...billing];
      value[0].vehicleNumber = "";
      setBilling(value);
    }
  };

  const advanceValue=()=>{
    if(billing[0].paymentType == "NoPayment"){
    if(billing[0].advanceAmount>0){
      let value = [...billing];
      value[0].advanceAmount = 0;
      setBilling(value);
    }
  }
  }



  useEffect(()=>{
    advanceValue();
  },[billing])
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    updateValue();
  }, [billingDetails]);





  const validation=()=>{

const header=billing[0].header;
const tripLocation=billing[0].tripLocation;
const tripDays=billing[0].tripDays;
const tripKM=billing[0].tripKM;
const perKmAmount=billing[0].perKmAmount;
const perDayAmount=billing[0].perDayAmount;
const permit=billing[0].permit;
const Discount=billing[0].Discount;
const advance=billing[0].advance;

let value=[...billingError];

if(tripLocation.length>=3){
value[0].tripLocation="";
}else{
value[0].tripLocation="tripLocation";
}

if(Number(tripDays)>=1){
  value[0].tripDays="";
  }else{
  value[0].tripDays="tripDays";
  }

  if(Number(tripKM)>=1){
    value[0].tripKM="";
    }else{
    value[0].tripKM="tripKM";
    }

    if(Number(perKmAmount)>=1){
      value[0].perKmAmount="";
      }else{
      value[0].perKmAmount="perKmAmount";
      }

      if(Number(perDayAmount)>=1){
        value[0].perDayAmount="";
        }else{
        value[0].perDayAmount="perDayAmount";
        }
      
        // if(Number(permit)>=0){
        //   value[0].permit="";
        //   }else{
        //   value[0].permit="permit";
        //   }
        if(Number(Discount)>=1){
          value[0].Discount="";
          }else{
          value[0].Discount="Discount";
          }

          if(Number(advance)>=1){
            value[0].advance="";
            }else{
            value[0].advance="advance";
            }
  
setBillingError(value);

  }

 
  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <div className="d-flex row  justify-content-end">
        <Link to="/home/QuotationReport" className="btn col-1">Back</Link>
        </div>
          <h1 className="page-title text-center fs-1">
            {billingDetails?.item?.id ? "Quotation Update" : "Quotation Create"}
          </h1>
          <div className="forms-sample pt-2">
          <div className="form-group">
              <label for="exampleInputName1" className="text-black fs-5">
              Quotation Header
              </label>
              <input
                className="form-control fs-6"
                id="exampleInputName1"
                value={billing[0].header}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].header = e.target.value;
                  setBilling(value);
                }}
              />
             {/* {billingError[0].driverName =="driver"&& <p className="text-danger">Please Select Header </p>} */}
            </div>
            <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5 d-flex">
                Trip Location<p style={{color:"red"}}> *</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].tripLocation}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].tripLocation = e.target.value;
                  setBilling(value);
                }}
              />
               {billingError[0].tripLocation =="tripLocation" && <p className="text-danger">Please Enter Valid Trip Location </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputPassword4" className="text-black fs-5 d-flex">
                Trip Days <p style={{color:"red"}}>*</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].tripDays}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].tripDays =e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
              />
               {billingError[0].tripDays=="tripDays"&& <p className="text-danger">Please Enter Valid Trip Days </p>}
            </div>
            </div>
            <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5 d-flex">
                Trip Total KM <p style={{color:"red"}}>*</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].tripKM}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].tripKM = e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
                disabled={billingDetails?.customer_id}
              />
               {billingError[0].tripKM=="tripKM"&& <p className="text-danger">Please Enter Trip KM </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputPassword4" className="text-black fs-5 d-flex">
                Trip Per KM Amount <p style={{color:"red"}}>*</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].perKmAmount}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].perKmAmount = e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
              />
               {billingError[0].perKmAmount=="perKmAmount"&& <p className="text-danger">Please Enter Per KM Amount </p>}
            </div>
            </div>
            <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5 d-flex">
                Trip Per Day Amount <p style={{color:"red"}}>*</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].perDayAmount}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].perDayAmount = e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
                disabled={billingDetails?.customer_id}
              />
               {billingError[0].perDayAmount=="perDayAmount"&& <p className="text-danger">Please Enter Per Day Trip Amount </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5 pb-3">
                Trip Permit Amount
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].permit}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].permit = e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
               
              />
               {billingError[0].dropLocation =="dropLocation"&& <p className="text-danger">Please Enter Valid Drop Location </p>}
            </div>
            </div>
            <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5 d-flex">
                Discount Amount <p style={{color:"red"}}>*</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].Discount}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].Discount = e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
                disabled={billingDetails?.customer_id}
              />
               {billingError[0].Discount=="Discount"&& <p className="text-danger">Please Enter Discount Amount </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputPassword4" className="text-black fs-5 d-flex">
              Advance Amount <p style={{color:"red"}}>*</p>
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].advance}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].advance =e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '');
                  setBilling(value);
                }}
              />
               {billingError[0].advance =="advance"&& <p className="text-danger">Please Enter Advance Amount </p>}
            </div>
            </div>

            <div className="row form-group">
            <label for="exampleInputPassword4" className="text-black fs-5">
              GST Bill
            </label>
            <div className="d-flex ">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                onChange={(e) => {
                  let value = [...billing];
                  value[0].gst = "with";
                  setBilling(value);
                }}
                checked={billing[0]?.gst === "with"}
              />
              <label className="form-check-label" for="inlineRadio1">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                onChange={(e) => {
                  let value = [...billing];
                  value[0].gst = "without";
                  setBilling(value);
                }}
                checked={billing[0]?.gst === "without"}
              />
              <label className="form-check-label" for="inlineRadio2">
                No
              </label>
            </div>
            </div>
            {billingError[0].gst =="gst"&& <p className="text-danger">Please Select Bill Type </p>} 
            </div>

            <div className="form-group">
              <label for="exampleInputName1" className="text-black fs-5">
             Notes
              </label>
              <input
                className="form-control fs-6"
                id="exampleInputName1"
                value={billing[0].notes}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].notes = e.target.value;
                  setBilling(value);
                }}
              />
             {billing[0].driverName?.phone_number&& <p className="text-success">Driver Mobile No:<span className="text-black">{billing[0].driverName?.phone_number}</span></p>}
             {billingError[0].driverName =="driver"&& <p className="text-danger">Please Select Valid Driver </p>}
            </div>
            <div className="d-flex col-12  justify-content-center">
              <div className="col-4">
                <button
                  // type="submit"
                  onClick={() =>bookingForm()}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotation;
