import * as ReactDOM from "react-dom";
import * as React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./invoice_Style.css";
import { CiLocationOn } from "react-icons/ci";
import { IoMdCall } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { FaAddressCard } from "react-icons/fa";

function Invoice({invoiceDetail}) {
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };



  const originalDate = new Date(invoiceDetail?.item?.createdAt);
const formattedDate = originalDate.toLocaleDateString("en-US", {
  month: "short", // Short month name
  day: "numeric",  // Day of the month
  year: "numeric"  // Full year
});
  const bookingDate = new Date(invoiceDetail?.item?.booking_date);
const bookedDate =bookingDate.toLocaleDateString("en-GB", {
  day: "2-digit",   // Two-digit day
  month: "2-digit", // Two-digit month
  year: "numeric"   // Full year
});

// const taxableValue=()=>{
//  if(invoiceDetail?.payment?.cgst == null){
//   const invoiceAmt=Number(invoiceDetail?.item?.bill_amount);
//   return invoiceAmt;
//  }else if(invoiceDetail?.payment?.cgst){
//   const invoiceAmt=invoiceDetail.item?.bill_amount;
//   return Math.round(Number(invoiceDetail.item?.bill_amount)*100/105); 
//  }
// }
// console.log(invoiceDetail); 

  return (
    <div>
      <div ref={printRef}>
        <div className="card">
          <div className="card-body">
            <div className="container mb-5 mt-4">
              <div className="row d-flex align-items-baseline mt-4">
                <h1 className="text-center mt-4">
                  TAXINIYAS SERVICES PRIVATE LIMITED
                </h1>
                <hr style={{ borderTop: "2px solid black" }} />
              </div>

              <div className="container">
                <div className="col-md-12">
                  <div className="text-center">
                    <i
                      className="fab fa-mdb fa-4x ms-0"
                      style={{ color: "#5d9fc5" }}
                    ></i>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-xl-8">
                    <ul className="list-unstyled">
                      <li className="text-muted d-flex">
                        <h3 style={{ color: "black" }}>TAXI NOW </h3>&nbsp;
                        <p> AT</p>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          <CiLocationOn /> &nbsp;M 40,P RM COLONY, MAIN ROAD,
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DINDIGUL-624001.
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          <IoMdCall /> &nbsp;9789590070
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          <CiMail />
                          &nbsp;&nbsp;taxinowtamilnadu@gmail.com
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          GST NO: 33AAKCT2478C1ZE
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>PAN No: AAKCT2478C</h6>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-4">
                    <h5 className="text">Invoice</h5>
                    <ul className="list-unstyled">
                      <li className="text-">
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        ></i>{" "}
                        <span className="fw-bold">No:</span>{invoiceDetail?.item?.booking_id}
                      </li>
                      <li className="text">
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        ></i>{" "}
                        <span className="fw-bold">Date: </span>{formattedDate}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr style={{ borderTop: "2px solid black" }} />
                <div className="row mt-4">
                  <div className="col-xl-8">
                    <ul className="list-unstyled">
                      <li className="text-muted d-flex">
                        <h3 style={{ color: "black" }}>
                          TO :{invoiceDetail?.item?.customer?.customer_name} {" "}
                        </h3>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          <CiLocationOn /> &nbsp;{invoiceDetail?.item?.customer?.address}
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          {" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{invoiceDetail?.item?.customer?.city}
                        </h6>
                      </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          <IoMdCall /> &nbsp;{invoiceDetail?.item?.customer?.phone_number}
                        </h6>
                        </li>
                      <li className="text-muted">
                        <h6 style={{ color: "black" }}>
                          GST NO:  &nbsp;{invoiceDetail?.item?.customer?.gst_no?invoiceDetail?.item?.customer?.gst_no:""}
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row mt-2 mx-1 justify-content-center table">
                  <table className="table  table-borderless">
                    <thead
                      style={{ backgroundColor: "#84B0CA" }}
                      className="text-white"
                    >
                      <tr>
                        <th scope="col" className="text-center fs-6">
                          DATE
                        </th>
                        <th scope="col" className="text-center fs-6">
                          DESCRIPTION
                        </th>
                        <th scope="col" className="text-center fs-6">
                          AMOUNT
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="col-12">
                        <td className="text-center col-2 fs-6">{bookedDate}</td>
                        <td className="col-8 tableDetail fs-6">
                          <p style={{fontSize:"16px"}}>
                          {invoiceDetail?.item?.pickup_location} To {invoiceDetail?.item?.dropoff_location} 
                          </p>
                          <p>{invoiceDetail?.item?.Notes}</p>
                        </td>
                        <td className="col-2 fs-6 text-end">₹{invoiceDetail.payment?.cgst?Math.round(Number(Number(invoiceDetail.item?.bill_amount)*100/112)):invoiceDetail.item?.bill_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-xl-8">
                    <p className="ms-3 mt-3">
                      <h6>OUR BANK DETAILS</h6>
                      <div className="d-flex">
                        BANK NAME<h6 className="mt-1">: HDFC BANK</h6>
                      </div>
                      <div className="d-flex mt-1">
                        BRANCH<h6 className="mt-1">:DINDIGUL</h6>
                      </div>
                      <div className="d-flex mt-1">
                        IFS CODE<h6 className="mt-1">:HDFC0001850</h6>
                      </div>
                      <div className="d-flex mt-1">
                        ACCOUNT NO<h6 className="mt-1">:50200082343</h6>
                      </div>
                      <div className="d-flex mt-1">
                        ACCOUNT TYPE<h6 className="mt-1">: CASH & CREDIT</h6>
                      </div>
                    </p>
                  </div>
                  <div className="col-xl-4">
                    <li className="text-muted ms-3 mt-3 d-flex justify-content-between">
                      <td className="text-black">SubTotal</td>
                      <td className="text-black">₹{invoiceDetail.payment?.cgst?Math.round(Number(Number(invoiceDetail.item?.bill_amount)*100/112)):invoiceDetail.item?.bill_amount} </td>
                    </li>
                    <hr style={{ borderTop: "2px solid black" }} />
                    <li className="text-muted ms-3 mt-3 d-flex justify-content-between">
                      <td className="text-black">CGST @ 6%</td>
                      <td className="text-black">₹{invoiceDetail.payment.cgst ?invoiceDetail.payment.cgst :0}  </td>
                    </li>
                    <li className="text-muted ms-3 mt-3 d-flex justify-content-between">
                      <td className="text-black">SGST @ 6%</td>
                      <td className="text-black">₹{invoiceDetail.payment.sgst ?invoiceDetail.payment.cgst :0} </td>
                    </li>

                    <hr style={{ borderTop: "2px solid black" }} />
                    <p className="text-black mt-3 justify-content-between d-flex">
                      <h5 className="text-black me-3"> Total Amount</h5>
                      <h5 className=" ">₹{invoiceDetail?.item?.bill_amount}</h5>
                    </p>
                  </div>
                </div>
                <hr style={{ borderTop: "2px solid black" }} />
                <div className="row">
                  <div className="col-6">
                  </div>
                  <div className="col-6">
                    <h6 className="text-center">TAXINIYAS SERVICES PRIVATE TIMITED</h6>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h6  className="text-center">Authorized Signature</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
      <button type="button" className="btn col-2" onClick={handleDownloadPdf}>
        Download Invoice
      </button>
      </div>
    </div>
  );
}

export default Invoice;
