import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {api} from'../api'
import { useNavigate } from 'react-router-dom';


const VendorCreation=({vendorDetail,setVendorDetail,back})=>{
  const [vendor,setVendor]=useState([{vendorName:"",vendorMobile:"",vendorAddress:""}]);
  const [error,setError]=useState({name:"",mobile:"",address:""});


  const newVendor=async()=>{
    if(vendorDetail?.vendor_id){
    const vendorName=vendor[0].vendorName;
    const vendorMobile=vendor[0].vendorMobile;
    const vendorAddress=vendor[0].vendorAddress;

    function isValidMobileNumber(vendorMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(vendorMobile);
    }
    if (isValidMobileNumber(vendorMobile)) {
    if(vendorName.length>=3 && vendorMobile.length == 10 && vendorAddress.length>=3){
      const id=vendorDetail.vendor_id;
      const createVendor=await axios.post(`${api.api}/vendor/updateVendor`,{vendorName,vendorMobile,vendorAddress,id});
      setVendorDetail(createVendor.data.vendor);
  setVendor([{vendorName:"",vendorMobile:"",vendorAddress:"",vendorCity:""}]);
  // console.log(createVendor);
  alert("Update Successfully");
  back();
   }else{
  alert("Please Give Valid Information")
   }
   } else {
   alert("Invalid mobile number");
   }
  }else{
    const vendorName=vendor[0].vendorName;
    const vendorMobile=vendor[0].vendorMobile;
    const vendorAddress=vendor[0].vendorAddress;

    function isValidMobileNumber(vendorMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(vendorMobile);
    }
      if(vendorName.trim().length>=3){
        if (isValidMobileNumber(vendorMobile)) {
        if(vendorMobile.trim().length == 10){
         if(vendorAddress.trim().length>=3){
            const createVendor=await axios.post(`${api.api}/vendor/createVendor`,{vendorName,vendorMobile,vendorAddress});
            
            // console.log(createVendor.data.message == "UnSuccess");
            if(createVendor.data.message == "UnSuccess"){
              alert("Already Existing Vendor ")
            }else{
            alert("Successfully Created");
            setVendor([{vendorName:"",vendorMobile:"",vendorAddress:"",vendorCity:""}]);
            // setError("");
            }
          }else{
            // setError("address");
          }
        }else{
          // setError("mobile");
        }
      } else {
        // setError("mobile");
      }

      }else{
        // setError("name");
      } 
}
validation();
  }


  let navigate = useNavigate();
  const updateValue=()=>{
    if(vendorDetail?.vendor_id){
      navigate('/home/vendorsUpdate')
      let value=[...vendor];
      value[0].vendorName=vendorDetail.name;
      value[0].vendorMobile=vendorDetail.phone_number;
      value[0].vendorAddress=vendorDetail.address;
      setVendor(value);
    }
  }

  const validation=()=>{
    const vendorName=vendor[0].vendorName;
    const vendorMobile=vendor[0].vendorMobile;
    const vendorAddress=vendor[0].vendorAddress;

    function isValidMobileNumber(vendorMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(vendorMobile);
    }

    let value={...error};
      if(vendorName.trim().length>=3){
        value.name="";
      }else{
        value.name="name";
      } 

      if (isValidMobileNumber(vendorMobile)) {
        value.mobile="";
      } else {
        value.mobile="mobile";
      }

      if(vendorMobile.trim().length == 10){
        value.mobile="";
       }else{
        value.mobile="mobile";
       }

       if(vendorAddress.trim().length>=3){
        value.address="";
       }else{
        value.address="address";
       }
       setError(value);
  }

  useEffect(()=>{
    updateValue();
  },[])
  useEffect(()=>{
    updateValue();
  },[vendorDetail]);


  const value=localStorage.getItem('taxinow')?localStorage.getItem('taxinow'):""

  useEffect(()=>{
if(localStorage.getItem('taxinow')){

}else{
  navigate('/home/customerReport');
}
  },[value])

  // console.log(vendor);
  // console.log(vendorDetail);
    return(
      <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h1 className="page-title text-center fs-1"> {vendorDetail?.vendor_id?'Vendor Update':'Vendor Creation'}</h1>
          {/* <p className="card-description"> Basic form elements </p> */}
          <div className="forms-sample pt-2">
          <div className='row'>
            <div className="form-group col-md-6">
              <label for="exampleInputName1" className='text-black fs-5'>Name</label>
              <input type="text" className="form-control fs-6" id="exampleInputName1" value={vendor[0].vendorName} onChange={(e)=>{let value=[...vendor]; value[0].vendorName=e.target.value; setVendor(value) }}/>
              <p className='text-danger'>{error.name =="name"&&"Please Enter Valid Name"}</p>
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputEmail3"  className='text-black fs-5'>Mobile No</label>
              <input type="text"  className="form-control fs-6" id="exampleInputEmail3" value={vendor[0].vendorMobile} onChange={(e)=>{let value=[...vendor]; value[0].vendorMobile=e.target.value === '0' ? '' : e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, ''); setVendor(value) }} disabled={vendorDetail?.vendor_id} />
              <p className='text-danger'>{error.mobile =="mobile"&&"Please Enter Valid Mobile No"}</p>
            </div>
            </div>
            <div className="form-group">
              <label for="exampleInputPassword4"  className='text-black fs-5'>Address</label>
              <input type="text"  className="form-control fs-6" id="exampleInputPassword4" value={vendor[0].vendorAddress} onChange={(e)=>{let value=[...vendor]; value[0].vendorAddress=e.target.value; setVendor(value) }} />
              <p className='text-danger'>{error.address =="address"&&"Please Enter Valid Address"}</p>
            </div>
            <div className='d-flex col-12  justify-content-center'>
           <span className='col-4'><button type="submit" onClick={()=>{newVendor();}} className="btn btn-primary mr-2">Submit</button></span> 
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default VendorCreation;