import React from 'react';
import {
    MaterialReactTable,
    createMRTColumnHelper,
    useMaterialReactTable,
  } from 'material-react-table';
  import { Box, Button } from '@mui/material';
  import FileDownloadIcon from '@mui/icons-material/FileDownload';
  import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

    
  import { jsPDF } from 'jspdf'; 
  import autoTable from 'jspdf-autotable';
  import { MdOutlinePictureAsPdf } from "react-icons/md";


import { useEffect, useState } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import {api} from'../../api';
import Quotation from './Quotation';
import { TbFileInvoice } from "react-icons/tb";
import Invoice from './Invoice/invoice';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


  
  const QuotationReport = () => {
    
    const [data,setData]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [editBilling,setBilling]=useState();
    const[customerDetail,setBillingDetail]=useState([]);
    const [editData,setEditData]=useState({});
    const [billInvoice,setBillInvoice]=useState(false);

  const handleOpen =(data) => {
    setOpen(true);
    setEditData(data)
  }
  const handleClose = () => setOpen(false)


    const handleEdit = (rowData) => {
    //  console.log(rowData);
    // const check=(localStorage.getItem("login"));
    // console.log(check.toLowerCase().includes("regular"));
    // if(check.toLowerCase().includes("regular")){
     setBillInvoice(false);
     setBilling(rowData);
    // }else{
    //   alert("Booking Edit Only for Authorized Person");
    // }
    };

    const handleInvoice = (rowData) => {
      // console.log(rowData);
      setBilling(rowData);
      setBillInvoice(true);
     };

    const handleDelete = async(rowData) => {
      // console.log(rowData.item.is_active)
      // const status=rowData.item.is_active == true? false :true;
      const id=rowData.item.id;
      const customerActive=await axios.post(`${api.api}/quotation/isActiveQuotation`,{id});
      // console.log(id);
      // console.log(rowData);
      setBillingDetail(customerActive);
    };

    const billingData=async()=>{
      const apiData=await axios.get(`${api.api}/quotation/showQuotation`);
      // console.log(apiData.data.booking);
      const value=apiData.data.quotation;
      const database=value.map(item=>{
        return ({item});
      })
      setData(database);
      // console.log(database);
    }

    // console.log(data);
    const columnHelper = createMRTColumnHelper();

    const columns = [
          // columnHelper.accessor('item.id', {
          //   header: 'Quotation No',
          //   size: 40,
          // }),
          columnHelper.accessor('item.createdAt', {
            id: 'item.createdAt', // Unique ID for the column
            header: 'Date', // Header title for the column
            filterVariant: 'date-range', // Filter variant for filtering dates by range
            size: 20, // Size or width of the column (assuming in pixels)
            accessorFn: (originalRow) => originalRow?.item.createdAt ? new Date(originalRow?.item.createdAt) : 0, // Function to convert to Date for sorting and filtering
            Cell: ({ cell }) => {
                if (!cell || !cell.getValue()) return undefined; // Check if cell or cell value is null
                return cell.getValue().toLocaleDateString();
            },
        }),
          columnHelper.accessor('item.location', {
            header: 'Trip Location',
            size: 40,
          }),
          columnHelper.accessor('item.trip_day', {
            header: 'Trip Days',
            size: 40,
          }),
          columnHelper.accessor('item.per_day_amount', {
            header: 'Per Day Amount',
            size: 40,
          }),
          columnHelper.accessor('item.trip_km', {
            header: 'Trip KM',
            size: 40,
          }),
          columnHelper.accessor('item.per_km_amount', {
            header: 'Trip Per KM Amount',
            size: 40,
          }),
          columnHelper.accessor('item.discount', {
            header: 'Discount',
            size: 40,
          }),
          columnHelper.accessor('item.advance', {
            header: 'Advance',
            size: 40,
          }),
          columnHelper.accessor('item.city',{
            accessor: 'action',
            header: 'Invoice',
            size: 40,
            Cell:({row},rowIndex) => {
              // console.log(row);
              return(
                <Box>
              <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() =>handleInvoice(row.original)}>
                <TbFileInvoice className='text-info' />
                </IconButton>
              </Box>)
              }
            }),
          columnHelper.accessor('item.is_active',{
            accessor: 'action',
            header: 'Action',
            size: 40,
            Cell:({row},rowIndex) => {
              // console.log(row);
              return(
                <Box>
               {/* <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="View" >
                <VisibilityIcon className='text-primary fs-6' onClick={()=>handleOpen(row)} />
                </IconButton> */}
                 {/* } */}
                {row.original.item.is_active === true && <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => handleEdit(row.original)}>
                <EditIcon className='fs-6'/>
                </IconButton>}
                <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title={row.original.item.is_active == true?"Delete":"Active"} onClick={() => handleDelete(row.original)}>
                {row.original.item.is_active === true ?<CancelIcon className='text-danger fs-6'/>:<CheckCircleIcon className='text-success fs-6'/>}
                </IconButton>
              </Box>)
              }
            }),
        ];
          
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

    const handleExportRows = (rows) => {
      const rowData = rows.map((row) => row.original);
      const value = rowData.map(n => {
        return n
      });
      // console.log(value);
      const item=value.map(single=>{
        return ({
          "Quotation No":single.item.id,
          "Quotation Date":single.item.createdAt?new Date(single.item.createdAt).toLocaleDateString(undefined, {year: 'numeric',month: 'long',day: 'numeric',}):" ",
          "Trip Location":single.item.location,
          "Trip Days":single.item.trip_day+"Day",
          "Trip Per Day Amount":single.item.per_day_amount,
          "Trip KM":single.item.trip_km,
          "Trip Per KM Amount":single.item.per_km_amount,
          "Discount":single.item.discount,
          "Advance":single.item.advance,
        })
      })
      const csv = generateCsv(csvConfig)(item);
      download(csvConfig)(csv);
    };
  

    const handleExportData = () => {
      const value = data.map(n => {
    return n
  });

  const item=value.map(single=>{
    return ({
      "Quotation No":single.item.id,
      "Quotation Date":single.item.createdAt?new Date(single.item.createdAt).toLocaleDateString(undefined, {year: 'numeric',month: 'long',day: 'numeric',}):" ",
      "Trip Location":single.item.location,
      "Trip Days":single.item.trip_day+"Day",
      "Trip Per Day Amount":single.item.per_day_amount,
      "Trip KM":single.item.trip_km,
      "Trip Per KM Amount":single.item.per_km_amount,
      "Discount":single.item.discount,
      "Advance":single.item.advance,
    })
  })
      const csv = generateCsv(csvConfig)(item);
      download(csvConfig)(csv);
    };
  
    const handleExportRowsPDF = (rows) => {
      const rowData = rows.map((row) => row.original);
      // console.log(rowData);
      const value = rowData.map(n => {
        return n
      });
    
      const item=value.map(single=>{
        return ({
          "Quotation No":single.item.id,
          "Quotation Date":single.item.createdAt?new Date(single.item.createdAt).toLocaleDateString(undefined, {year: 'numeric',month: 'long',day: 'numeric',}):" ",
          "Trip Location":single.item.location,
          "Trip Days":single.item.trip_day+"Day",
          "Trip Per Day Amount":single.item.per_day_amount,
          "Trip KM":single.item.trip_km,
          "Trip Per KM Amount":single.item.per_km_amount,
          "Discount":single.item.discount,
          "Advance":single.item.advance,
        })
      })
      
      // const doc = new jsPDF();
      const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape
        format: [200, 500],
      });
      const tableData = item.map((row) => Object.values(row));
      const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
      // console.log(tableHeaders);
      doc.save('Quotation_Report.pdf');
    };

    const handleExportAllRowsPDF = (rows) => {
      const rowData = data.map((row) => row);
      // console.log(rowData);
      const value = rowData.map(n => {
        return n
      });
    
      const item=value.map(single=>{
        return ({
          "Quotation No":single.item.id,
          "Quotation Date":single.item.createdAt?new Date(single.item.createdAt).toLocaleDateString(undefined, {year: 'numeric',month: 'long',day: 'numeric',}):" ",
          "Trip Location":single.item.location,
          "Trip Days":single.item.trip_day+"Day",
          "Trip Per Day Amount":single.item.per_day_amount,
          "Trip KM":single.item.trip_km,
          "Trip Per KM Amount":single.item.per_km_amount,
          "Discount":single.item.discount,
          "Advance":single.item.advance,
        })
      })
      
      // const doc = new jsPDF();
      const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape
        format: [200, 500],
      });
      const tableData = item.map((row) => Object.values(row));
      const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
      // console.log(tableHeaders);
      doc.save('Quotation_Report.pdf');
    };
    
    const table = useMaterialReactTable({
      columns,
      data,
      enableRowNumbers: true,
      rowNumberDisplayMode: 'original',
      // enableRowSelection: true,
      enableFullScreenToggle: false,
      columnFilterDisplayMode: 'popover',
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      enableGlobalFilter: false,
      enableStickyHeader: true,
      enableGlobalFilter: false,
      initialState: { pagination: { pageSize: 100, pageIndex: 0, }, density: 'compact' },
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
            All
          </Button>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
            Filter
          </Button>
          <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportAllRowsPDF()
          }
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
         All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
        Filter
        </Button>
          <Button>
          <Link to="/home/CreateQuotation">Create</Link>
          </Button>
        </Box>
      ),
    });
  

    // console.log(data)
    useEffect(()=>{
      billingData();
    },[]);

    useEffect(()=>{
      billingData();
    },[editBilling]);
    

    useEffect(()=>{
      billingData();
    },[customerDetail]);
    
    // console.log(editBilling);

    
    let navigate = useNavigate();
    useEffect(()=>{
      if(editBilling == undefined){
        // navigate('/home/bookingReport');
      }
    },[editBilling]);

    return( 
      <div>
       {editBilling?.item?.id>0?<div><div className='d-flex col-12 justify-content-end'>
        <button className='btn btn-primary col-2'onClick={()=>{setBilling();setBillInvoice("");}} >Back</button></div>
        {billInvoice == true ?
        <Invoice invoiceDetail={editBilling}/>
        :
        <Quotation billingDetails={editBilling} setEditBilling={setBilling} back={setBilling}/> 
        } 
        </div>
        :<div><h1 className='text-center'>Quotation</h1><LocalizationProvider dateAdapter={AdapterDayjs}><MaterialReactTable table={table} /></LocalizationProvider></div>}
        { open &&  <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
            <Box sx={style}>
            <DialogContent>
            <button
          onClick={handleClose}
          className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
        >
          X
        </button>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Customer name"
            type="text"
            fullWidth
            defaultValue={editData.original.item.customer.customer_name}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="mobile"
            label="Customer Mobile No"
            type="email"
            fullWidth
            defaultValue={editData.original.item.customer.phone_number}
            InputProps={{
              readOnly: true,
            }}
          />
           <TextField
            margin="dense"
            id="mobile"
            label="Customer Address"
            type="address"
            fullWidth
            defaultValue={editData.original.item.customer.address}
            InputProps={{
              readOnly: true,
            }}
          />
           <TextField
            margin="dense"
            id="mobile"
            label="Customer City"
            type="city"
            fullWidth
            defaultValue={editData.original.item.customer.city}
            InputProps={{
              readOnly: true,
            }}
          />
          
        </DialogContent>
           </Box>
              </Modal>}
        </div>
    );
  };
  
  export default QuotationReport;
  