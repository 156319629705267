import React, { useState, useEffect } from 'react';
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import './CalanderStyle.css';
import {api} from'../api';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from "react-icons/md";

import { TfiCheck } from "react-icons/tfi";
import { TfiClose } from "react-icons/tfi";
import {IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import { style } from '@mui/system';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { MdNoteAlt } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, '')+'T12:00:00';

// export const INITIAL_EVENTS = [
//   {
//     id: createEventId(),
//     title: 'All-day event',
//     start: todayStr,
//   },
//   {
//     id: createEventId(),
//     title: 'Timed event',
//     start: todayStr + 'T12:00:00',
//   },
// ];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  // bgcolor: 'background.paper',
  bgcolor: 'white',
  // color:'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createEventId() {
  return String(eventGuid++);
}


function DriverAttendance({driver}) {
  const [data,setData]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [editData,setEditData]=useState({});
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [INITIAL_EVENTS,setEvents]=useState([]);
  const [loading, setLoading] = useState(true);
  const [dataUpdate,setDataUpdate]=useState(false);



  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data)
  }
  // console.log(editData);
  const handleClose = () => setOpen(false)

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const handleDateSelect = async(selectInfo) => {
    // console.log(new Date());
    try{

    if(new Date(selectInfo?.startStr)<=new Date()){
      let title = prompt('Please enter a new title for your event');
      setDataUpdate(false);
      let calendarApi = selectInfo.view.calendar;
      let id=driver.driver_id;
      let attendanceDate=selectInfo.startStr;
    // console.log(calendarApi);
    // console.log(id);
    // console.log(title);
    // console.log(attendanceDate);
    if(title !== null && title.trim().length>1){
    const response = await axios.post(`${api.api}/driver/driverAttendanceNoteCreate`,{id,attendanceDate,title});
    setDataUpdate(true);
    // console.log(response);
    }
    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     // allDay: selectInfo.allDay,
    //   });
    // }
  }
}catch(err){

}
  };


  const handleEventClick = async(clickInfo) => {
    // console.log(clickInfo);
  try{
    // console.log(clickInfo)
    if (clickInfo) {
     setDataUpdate(false);
      const id=clickInfo.event._def.publicId; 
      // console.log(id);
      // console.log(clickInfo.event);
      const response = await axios.post(`${api.api}/driver/driverAttendanceDelete`,{id});
      // console.log(response);
      setDataUpdate(true);
      // console.log(response);
      // clickInfo.event.remove();
      // clickInfo._def.extendedProps.remove()
    }
    }catch(err){
    alert("Notes Not Removed");
    }
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };


  const driverDetail = async () => {
    const driver_id=driver?.driver_id;
      const response = await axios.get(`${api.api}/driver/driverAttendance`,{params:{driver_id}});
      
      const events = response.data.data.map(item => ({
        id: item.id,
        title: item.status  ? "Present":"Absent",
        notes: item.Notes,
        start: new Date(item.date).toISOString().slice(0, 19),
        status: item.status,
        // createdAt:new Date(item.createdAt).toISOString().slice(0, 19),
        // updatedAt: new Date(item.updatedAt).toISOString().slice(0, 19),
      }));

      setEvents(events);

  };

 
  let navigate = useNavigate();
 const handleAttendance=async(e)=>{
  const id=e.publicId;
  setLoading(id);
  const tittle=e.title == "Absent"?1 :0;
  setLoading(tittle);
  // console.log(id);
  // console.log(tittle);
const attendanceData=await axios.post(`${api.api}/driver/driverUpdate`,{id,tittle})
setLoading(attendanceData);
// navigate(`/home/driverAttendance`);
 }

  useEffect(() => {
    driverDetail();
  }, []);

   useEffect(() => {
    driverDetail();
  }, [dataUpdate]);

  useEffect(() => {
    driverDetail();
  }, [loading]);

  // console.log(INITIAL_EVENTS);

  // console.log(driver);
  
  return (
    <div className='demo-app'>
      {renderSidebar()}
      <div className='demo-app-main'>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView='dayGridMonth'
          editable={false}
          eventResourceEditable  ={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          events={INITIAL_EVENTS}
          isDraggable={ false}
          select={handleDateSelect}
          eventContent={renderEventContent}
          // eventClick={handleEventClick}
          eventsSet={handleEvents}
        />
      </div>
    </div>
  );

  function renderSidebar() {
    return (
      <div className='demo-app-sidebar'>
        {/* Sidebar content */}
      </div>
    );
  }

  function renderEventContent(eventInfo) {
    // console.log(eventInfo,"eventInfo")
    // console.log(eventInfo.event)
    return (
      <>
      <div className='row'>
      <div style={eventInfo.event.title == "Present" ?{color:"green"}:{color:"red"}}  onClick={(e)=>{handleAttendance(eventInfo.event._def)}}>
      <div  id='draggable-el' style={{cursor:'default'}}>{eventInfo.event._def.title}</div>
      </div>
      {/* <br/> */}
      </div>
      {/* <br/> */}
      {eventInfo.event._def.extendedProps.notes &&
   
   <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Notes"  onClick={()=>handleOpen(eventInfo)} >
             <MdNoteAlt className='text-primary ' />
             </IconButton>}
             
   {open &&  <Modal
           open={open}
           onClose={handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
         <Box sx={style}>
           <DialogContent>
           <button
          onClick={handleClose}
          className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
        >
          X
        </button>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
           < IconButton onClick={()=>{handleClose()}} ><IoMdClose className='text-left'  /></IconButton> 
           </div>
       <h4>Notes:</h4>
       <p style={{ overflowWrap: 'anywhere'}}>{editData?.event._def.extendedProps?.notes}</p>
       < IconButton  onClick={(e)=>{handleEventClick(editData)}}> <MdDelete style={{color:"red"}}/>Delete</IconButton>
       </DialogContent>
        </Box>
           </Modal>
          }
      </>
    );
  }
}

export default DriverAttendance;
