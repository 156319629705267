import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { api } from "../api";
import Autocomplete from '@mui/material/Autocomplete';
import{ createFilterOptions } from '@mui/material/Autocomplete';
import { Link, useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { setHours, setMinutes } from 'date-fns';
import { setHours, setMinutes, getMinutes, getHours,format,subDays  } from 'date-fns';


const BookingCreate = ({ billingDetails , setEditBilling,back}) => {
  const [billing, setBilling] = useState([
    {
      driverName: "",
      customerName: "",
      vehicleNumber: "",
      pickupLocation: "",
      dropLocation: "",
      bookingDate: formatDate(new Date()),
      // bookingTime: "",
      droppingTime: "",
      paymentType: "",
      billAmount: "",
      km:"",
      advanceAmount: "",
      gst: "without",
      notes:"",
      ac_vehicle:0,
    },
  ]);
  const[billingError,setBillingError]=useState([
    {
      driverName: "",
      customerName: "",
      vehicleNumber: "",
      pickupLocation: "",
      dropLocation: "",
      bookingDate:"",
      // bookingTime: "",
      droppingTime: "",
      paymentType: "",
      billAmount: "",
      advanceAmount: "",
      gst: "",
      notes:"",
      ac_vehicle:0,
      km:""
    },
  ]);

  const [buttonDisable,setButtonDisable]=useState(false);

  // console.log(billingError);
  // console.log(billing);

  const[bookingExpenses,setBookingExpenses]=useState([{diesel:"",driverBata:"",expensesNote:""}])

  const [startDate, setStartDate] = useState(new Date());

  const navigate = useNavigate();

  const [driverList, setDriverList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [changeCustomerList, setChangeCustomerList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  // console.log(billingDetails);
// console.log(billing);

  const updateValue = () => {
    if (billingDetails?.item.vehicle.vehicle_type == "Rent") {
    navigate('/home/bookingReport/Rent/Update');
    }else  if (billingDetails?.item.vehicle.vehicle_type == "Own") {
      navigate('/home/bookingReport/Own/Update');
    }
    if (billingDetails?.item) {
     
      // console.log("SetValue");
      let value = [...billing];
      const timeString = billingDetails?.item?.booking_time;

//       function extractHoursAndMinutes(timeString) {
//         const date = new Date(`2000-01-01T${timeString}`);
//         if (isNaN(date.getTime())) {
//           return '';
//         }      
//         const hours = date.getHours().toString().padStart(2, '0');
//   const minutes = date.getMinutes().toString().padStart(2, '0');
//   return `${hours}:${minutes}`;
// }
// console.log(billingDetails);

      value[0].driverName = billingDetails?.item?.driver;
      value[0].customerName = billingDetails?.item?.customer;
      value[0].vehicleNumber = billingDetails?.item?.vehicle;
      value[0].pickupLocation = billingDetails?.item?.pickup_location;
      value[0].dropLocation = billingDetails?.item?.dropoff_location;
      value[0].bookingDate = formatDate(new Date( billingDetails?.item?.booking_date));
      // value[0].bookingTime =extractHoursAndMinutes(timeString);
      value[0].droppingTime =formatDate( new Date(billingDetails?.item?.dropping_time));
      value[0].paymentType = billingDetails?.item?.payment_mode;
      value[0].billAmount =  billingDetails?.item?.gst=="with"? (Number(billingDetails?.item?.bill_amount)*100/112):(Number(billingDetails?.item?.bill_amount));
      value[0].advanceAmount = billingDetails?.item?.advance;
      value[0].gst = billingDetails?.item?.gst;
      value[0].ac_vehicle = billingDetails?.item?.ac_vehicle;
      value[0].notes = billingDetails?.item?.Notes;
      value[0].km = billingDetails?.item?.km;
      setBilling(value);
      let exp=[...bookingExpenses];
      exp[0].diesel= billingDetails?.item?.expenses[0]?.expenses_amount;
      exp[0].driverBata= billingDetails?.item?.expenses[1]?.expenses_amount;
      exp[0].expensesNote= billingDetails?.item?.expenses[1]?.expenses_notes;
      setBookingExpenses(exp);
    }

  };

  const fetchData = async () => {
    const database = await axios.get(`${api.api}/booking/allSubSelection`);
    // console.log(database.data);
    setDriverList(database?.data?.driver);
    setCustomerList(database?.data?.customer);
    setVehicleList(database?.data?.vehicle);
  };


  const bookingForm=async()=>{

    validation();
     const driverName=billing[0]?.driverName;
     const customerName= billing[0]?.customerName;
     const vehicleNo= billing[0]?.vehicleNumber;
     const pickupLocation= billing[0]?.pickupLocation;
     const dropLocation= billing[0]?.dropLocation;
     const bookingDate= new Date(billing[0]?.bookingDate);
    //  const bookingTime= billing[0]?.bookingTime;
     const droppingTime= new Date(billing[0]?.droppingTime);
     const paymentType= billing[0]?.paymentType;
     const billAmount= Number(billing[0]?.billAmount);
     const advanceAmount= Number(billing[0]?.advanceAmount);
     const gst= billing[0]?.gst;
     const notes= billing[0]?.notes;
     const ac_vehicle= billing[0]?.ac_vehicle;
     const km= billing[0]?.km;
     const id=billingDetails?.item?.booking_id;
    //  console.log(bookingDate.toString().length)

    if(!buttonDisable){
      setButtonDisable(true);
     if(billingDetails?.item){
     if(driverName?.driver_id>0 && customerName?.customer_id>0 && vehicleNo?.vehicle_id>0  && pickupLocation?.trim().length>2 && dropLocation?.trim().length>2 && bookingDate?.toString().length == 55 && droppingTime?.toString().length == 55 && billAmount>1 && gst?.length>2){
     
      if(paymentType =="NoPayment"){
        if( droppingTime>bookingDate){
          if (advanceAmount<=billAmount){
            if(bookingDate?.toString() !=="Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"){
              if(km>0){
          const bookTaxi=await axios.post(`${api.api}/booking/updateBooking`,{driverName,customerName,vehicleNo,pickupLocation,dropLocation,bookingDate,droppingTime,paymentType,billAmount,advanceAmount,gst,id,notes,ac_vehicle,km,bookingExpenses});
          alert("Successfully Updated");
          // navigate("/home/bookingReport");
          back("");
              }
            }else{
              alert("Please Select Booking Date");
            }
          }else{
            
          }
        }else{
          // validation();
        }
        
      }else if(paymentType =="Online" || paymentType =="Cash"){
        if(advanceAmount>0){
          if( droppingTime>bookingDate){
          if (advanceAmount<=billAmount){
            if(bookingDate?.toString() !=="Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"){
              if(km>0){
            const bookTaxi=await axios.post(`${api.api}/booking/updateBooking`,{driverName,customerName,vehicleNo,pickupLocation,dropLocation,bookingDate,droppingTime,paymentType,billAmount,advanceAmount,gst,id,notes,ac_vehicle,km,bookingExpenses});
            alert("Successfully Updated");
            // navigate("/home/bookingReport");
            back("");}
            }else{
              alert("Please Select Booking Date");
            }
          }else{
            
          }
          }else{
            // validation();
          }
          }
      }else if(paymentType ==""){
          alert("Please select Payment Type");
        }else{
          // validation();
          // console.log("All")
         
        }
      }else{
        // validation();
        
      }
     }else if(!billingDetails?.item){
      // console.log("ok")
     if(driverName?.driver_id>0 && customerName?.customer_id>0 && vehicleNo?.vehicle_id>0  && pickupLocation?.trim().length>2 && dropLocation?.trim().length>2 && bookingDate?.toString().length == 55 && droppingTime?.toString().length == 55 && billAmount>1 && gst?.length>2){
      // console.log("all come")
      if(paymentType =="NoPayment"){
        // console.log("No pay")
        if( droppingTime>bookingDate){
          
        // console.log("date")
          if (advanceAmount<=billAmount){
            
        // console.log("advance")
        if(bookingDate?.toString() !=="Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"){
          if(km>0){
      const bookTaxi=await axios.post(`${api.api}/booking/createBooking`,{driverName,customerName,vehicleNo,pickupLocation,dropLocation,bookingDate,droppingTime,paymentType,billAmount,advanceAmount,gst,notes,ac_vehicle,km,bookingExpenses});
      alert("Bill Created Successfully");
      setBilling([
        {
          driverName: "",
          customerName: "",
          vehicleNumber: "",
          pickupLocation: "",
          dropLocation: "",
          bookingDate: "",
          // bookingTime: "",
          droppingTime: "",
          paymentType: "",
          billAmount: "",
          advanceAmount: "",
          gst: "",
          notes:""
        },
      ]);
      navigate("/home/bookingReport/Own");
    }
    }else{
      alert("Please Select Booking Date");

    }
    }else{

    }
    }else{
      // validation();
    }
      
      }else if(paymentType =="Online" || paymentType =="Cash"){
        // console.log("c or online")
        // if(advanceAmount>1){
          // console.log('ok');
          if( droppingTime>bookingDate){
            // console.log("date")
            if (advanceAmount<=billAmount && advanceAmount>1){
              // console.log("advance")
              if(bookingDate?.toString() !=="Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"){
                if(km>0){
        const bookTaxi=await axios.post(`${api.api}/booking/createBooking`,{driverName,customerName,vehicleNo,pickupLocation,dropLocation,bookingDate,droppingTime,paymentType,billAmount,advanceAmount,gst,notes,ac_vehicle,km,bookingExpenses});
        alert("Bill Created Successfully");

        // setEditBilling("");
      setBilling([
        {
          driverName: "",
          customerName: "",
          vehicleNumber: "",
          pickupLocation: "",
          dropLocation: "",
          bookingDate: "",
          // bookingTime: "",
          droppingTime: "",
          paymentType: "",
          billAmount: "",
          advanceAmount: "",
          gst: "",
        },
      ]);
      navigate("/home/bookingReport/Own");
    }
    }else{
      alert("Please Select Booking Date");

    }
    }else{
      // console.log("Date")

    }
          }else{
            // console.log("Date Error")
            // console.log( bookingDate>droppingTime);
            // validation();
          }
        
          // const bookTaxi=await axios.post(`${api.api}/booking/createBooking`,{driverName,customerName,vehicleNo,pickupLocation,dropLocation,bookingDate,bookingTime,droppingTime,paymentType,billAmount,advanceAmount,gst});
      }else if(paymentType ==""){
          alert("Please select Payment Type");
          // console.log("p type")
        }else{
          // validation();
          // console.log("All")
        }
      }else{
      alert("Please give Valid Information");
      // validation();
     }
    }
  }
  }

  const handleDriver = (e, n) => {
    // console.log(n)
    if (n && n.driver_id) {
      // console.log(`Selected Driver ID: ${n.driver_id}`);
      let value = [...billing];
      value[0].driverName = n;
      setBilling(value);
    }else{
      let value = [...billing];
      value[0].driverName = "";
      setBilling(value);
    }
  };

  
  const handleCustomer = (e, n) => {

    //  console.log(n);
    //  console.log(`Selected Driver ID: ${n.customer_id}`);
    if (n && n.customer_id) {
      // console.log(n);
      // console.log(`Selected Driver ID: ${n.driver_id}`);
      let value = [...billing];
      value[0].customerName = n;
      setBilling(value);
    }
    else{
      let value = [...billing];
      value[0].customerName = "";
      setBilling(value);
     }
    // console.log(n);
  };

  const handleVehicle = (e, n) => {
    if (n && n.vehicle_id) {
      // console.log(n);
      // console.log(`Selected Driver ID: ${n.driver_id}`);
      let value = [...billing];
      value[0].vehicleNumber = n;
      setBilling(value);
    }else{
      let value = [...billing];
      value[0].vehicleNumber = "";
      setBilling(value);
    }
  };

  const advanceValue=()=>{
    if(billing[0].paymentType == "NoPayment"){
    if(billing[0].advanceAmount>0){
      let value = [...billing];
      value[0].advanceAmount = 0;
      setBilling(value);
    }
  }
  }



  useEffect(()=>{
    advanceValue();
  },[billing])
  
  useEffect(() => {
    // updateValue();
    fetchData();
  }, []);

  useEffect(() => {
    updateValue();
  }, [billingDetails]);

  useEffect(() => {
    // Log the formatted date for demonstration purposes
    // console.log(format(startDate, 'MMMM d, yyyy h:mm aa'));
  }, [startDate]);



  const validation=()=>{
const bookingDate = new Date(billing[0].bookingDate);
const droppingDate = new Date(billing[0].droppingTime);
const driverName=billing[0]?.driverName;
const customerName= billing[0]?.customerName;
const vehicleNo= billing[0]?.vehicleNumber;
const pickupLocation= billing[0]?.pickupLocation;
const dropLocation= billing[0]?.dropLocation;
const paymentType= billing[0]?.paymentType;
const billAmount= billing[0]?.billAmount;
const advanceAmount= billing[0]?.advanceAmount;
const gst= billing[0]?.gst;
const id=billingDetails?.item?.booking_id;
const km=billing[0]?.km;


let value=[...billingError];
if(!customerName?.customer_id>0){
 value[0].customerName="customer";
}else{
 value[0].customerName="";
} 

if(!driverName?.driver_id >0){
 value[0].driverName="driver";
}else{
   value[0].driverName="";
  }

if(!vehicleNo?.vehicle_id>0){
 value[0].vehicleNumber="vehicle";
}else{
   value[0].vehicleNumber="";
  }

if(pickupLocation?.trim().length <=2){
 value[0].pickupLocation="pickup";
}else{
   value[0].pickupLocation="";
  }  

if(dropLocation?.trim().length<=2){
 value[0].dropLocation="dropLocation";
}else{
   value[0].dropLocation="";
  }

if(bookingDate?.toString().length !== 55){
  if (bookingDate?.toString() =="Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)") {
    value[0].bookingDate="bookingDate";
  }else{
    value[0].bookingDate="";
  }
}else if(bookingDate == "Invalid Date"){
  value[0].droppingTime="dropDate";
  // console.log("null value")
}else if(bookingDate?.toString() =="Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)") {
  value[0].bookingDate="bookingDate";
}else{
   value[0].bookingDate="";
  } 

  // console.log(bookingDate);
if(droppingDate.toString().length == 55){
  if(droppingDate<=bookingDate){
   value[0].droppingTime="dropDate";
  }else{
    value[0].droppingTime="";
  }
  }else if ( bookingDate<=droppingDate) {
     value[0].droppingTime="dropDate";
      // console.log("Wrong");
    }
    else if((droppingDate == null)){
      value[0].droppingTime="dropDate";
      // console.log("null value")
    }else if(droppingDate == "Invalid Date"){
      value[0].droppingTime="dropDate";
      // console.log("null value")
    }else{
       value[0].droppingTime="";
        // console.log("Wrong");
      }
      
      

if(billAmount<1){
 value[0].billAmount="billAmount";
}
else{
   value[0].billAmount="";
  }

    if (advanceAmount<1){
      value[0].advanceAmount="advance";
     }else if (Number(advanceAmount)>Number(billAmount)){
      value[0].advanceAmount="advance";
     }else{
        value[0].advanceAmount="";
       }

if (gst?.length<2){
 value[0].gst="gst";
}else{
   value[0].gst="";
  }

 if(paymentType.length<2){
 value[0].paymentType="paymentType";
 }else{
  value[0].paymentType="";
  }

  if(!Number(km)>0){
    value[0].km="km";
  }else{
    value[0].km="";
  }
setBillingError(value);

// console.log(value);

  }

 
  // console.log(customerList);
  
  // console.log(billingError);
//   console.log(billingError);
//   console.log(billingDetails);

// console.log(billing[0]?.bookingDate.toString().length);


function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}


function getYesterdayDate() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const year = yesterday.getFullYear();
  const month = (yesterday.getMonth() + 1).toString().padStart(2, '0');
  const day = yesterday.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00`;
}


const value=localStorage.getItem('taxinow')?localStorage.getItem('taxinow'):""

useEffect(()=>{
if(localStorage.getItem('taxinow')){

}else{
navigate('/home/customerReport');
}
},[value])


useEffect(()=>{
  setButtonDisable(false)
},[billing])

  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
      {!billingDetails&&<div className='d-flex row justify-content-end'>
          <div className='col-3'>
          <Link to={'/home/bookingReport/Own'} className='btn btn-primary col-12'>Back</Link>
          </div>
        </div>}
        <div className="card-body">
          <h1 className="page-title text-center fs-1">
            {billingDetails?.item?.booking_id ? "Taxi Booking Update" : "Taxi Booking"}
          </h1>
          <div className="forms-sample pt-2">
          <div className='row justify-content-between mx-1'>
            <div className="form-group col-12 col-md-6 row">
              <label for="exampleInputPassword4" className="text-black fs-5 text-start p-0">
                Booking Date & Time
              </label>
              <input
                type="datetime-local"
                className="form-control fs-6"
                value={billing[0].bookingDate}
                onChange={(e) =>{ let value = [...billing];
                  value[0].bookingDate =e.target.value;
                  setBilling(value);
                // console.log(e);
              }}
                defaultValue={billing[0].bookingDate}
                min={getYesterdayDate()}
              />



                {/* <DatePicker
      className="form-control fs-6"
      selected={billing[0].bookingDate}
      onChange={(date) =>{ let value = [...billing];
        value[0].bookingDate =date;
        setBilling(value);}}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="dd,MMMM, yyyy h:mm aa"
      minDate={subDays(new Date(), 1)}
      withPortal
    /> */}
     {billingError[0].bookingDate =="bookingDate"&& <p className="text-danger p-0">Please Enter Valid Booking Date </p>}
            </div>
            <div className="form-group col-12 col-md-6  row">
              <label for="exampleInputPassword4" className="text-black fs-5 p-0">
                Dropping Time
              </label>
              <input
                type="datetime-local"
                className="form-control fs-6"
                id="exampleInputPassword4"
                value={billing[0].droppingTime}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].droppingTime = e.target.value;
                  setBilling(value);
                }}
                min={billing[0].bookingDate}
              />
               {/* <DatePicker
      className="form-control fs-6"
      selected={billing[0].droppingTime}
      onChange={(date) => {
        let value = [...billing];
        value[0].droppingTime = date;
        setBilling(value);
      }}
      // showTimeSelect={false}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="dd,MMMM, yyyy h:mm aa"
      minDate={new Date()}
    /> */}
     {billingError[0].droppingTime =="dropDate"&& <p className="text-danger p-0">Please Enter Valid Dropping Date and Time </p>}
            </div>
            </div>

          <div className='row'>
          <div className="form-group col-md-6">
              <label for="exampleInputName1" className="text-black fs-5">
                Customer Mobile No
              </label>
                <Autocomplete
                className="form-control fs-6"
                id="exampleInputName1"
                sx={{
                  display: "inline-block",
                  "& input": {
                    width: 200,
                    bgcolor: "background.paper",
                    color: (theme) =>
                      theme.palette.getContrastText(
                        theme.palette.background.paper
                      ),
                  },
                }}
                // filterOptions={(options, params) => {
                  
                //   const filtered = options.filter(customer => {
                //     // Implement your filtering logic here based on the customer and params
                //     // For example, if you want to filter by the phone_number property
                //     return customer.phone_number.toLowerCase().includes(params.inputValue.toLowerCase());
                //   });
                
                //   console.log(filtered);
                
                //   if (params.inputValue !== '') {
                //     filtered.push({
                //       inputValue: params.inputValue,
                //       // title: `Add "${params.inputValue}"`,
                //     });
                //   }
                
                //   return filtered;
                // }}
                options={customerList}
                onChange={(event, newValue) =>handleCustomer(event, newValue)}
                value={billing[0].customerName.phone_number}
                getOptionLabel={(option) => option.phone_number || ""}
                
               inputValue={billing[0].customerName.phone_number}
               onInputChange={(event, newValue) => handleCustomer(event, newValue)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="exampleInputName1"
                      style={{ border: "none" }}
                      {...params.inputProps}
                      value={billing[0].customerName.phone_number}
                    />
                  </div>
                )}
              />
               {billing[0].customerName?.phone_number&& <p className="text-success">Customer Name:<span className="text-black">{billing[0].customerName?.customer_name}</span></p>}
               {billingError[0].customerName =="customer"&& <p className="text-danger">Please Select Valid Customer </p>}
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputName1" className="text-black fs-5">
                Vehicle No
              </label>
               <Autocomplete
                className="form-control fs-6"
                id="exampleInputName1"
                sx={{
                  display: "inline-block",
                  "& input": {
                    width: 200,
                    bgcolor: "background.paper",
                    color: (theme) =>
                      theme.palette.getContrastText(
                        theme.palette.background.paper
                      ),
                  },
                }}
                options={vehicleList}
                onChange={(event, newValue) =>handleVehicle(event, newValue)}
                value={billing[0].vehicleNumber.vehicle_number}
                getOptionLabel={(option) => option.vehicle_number || ""}
               inputValue={billing[0].vehicleNumber.vehicle_number}
               onInputChange={(event, newValue) => handleVehicle(event, newValue)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="exampleInputName1"
                      style={{ border: "none" }}
                      {...params.inputProps}
                      value={billing[0].vehicleNumber.vehicle_number}
                    />
                  </div>
                )}
              />
               {billing[0].vehicleNumber?.vehicle_model&& <p className="text-success">Vehicle Model:<span className="text-black">{billing[0].vehicleNumber?.vehicle_model}</span></p>}
               {billingError[0].vehicleNumber =="vehicle"&& <p className="text-danger">Please Select Valid Taxi </p>}
            </div>
            </div>
            <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputName1" className="text-black fs-5">
                Driver Name
              </label>
              <Autocomplete
                className="form-control fs-6"
                id="exampleInputName1"
                sx={{
                  display: "inline-block",
                  "& input": {
                    width: 200,
                    bgcolor: "background.paper",
                    color: (theme) =>
                      theme.palette.getContrastText(
                        theme.palette.background.paper
                      ),
                  },
                }}
                options={driverList}
                onChange={(event, newValue) =>handleDriver(event, newValue)}
                value={billing[0].driverName?.driver_name}
                getOptionLabel={(option) => option.driver_name || ""}
                
               inputValue={billing[0].driverName.driver_name}
               onInputChange={(event, newValue) => handleDriver(event, newValue)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="exampleInputName1"
                      style={{ border: "none" }}
                      {...params.inputProps}
                      value={billing[0].driverName.driver_name}
                    />
                  </div>
                )}
              />
             {billing[0].driverName?.phone_number&& <p className="text-success">Driver Mobile No:<span className="text-black">{billing[0].driverName?.phone_number}</span></p>}
             {billingError[0].driverName =="driver"&& <p className="text-danger">Please Select Valid Driver </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5">
                Pickup Location
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].pickupLocation}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].pickupLocation = e.target.value;
                  setBilling(value);
                }}
                disabled={billingDetails?.customer_id}
              />
               {billingError[0].pickupLocation =="pickup"&& <p className="text-danger">Please Enter Valid Pickup Location </p>}
            </div>
            </div>
            <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputPassword4" className="text-black fs-5">
                Drop Location
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputPassword4"
                value={billing[0].dropLocation}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].dropLocation = e.target.value;
                  setBilling(value);
                }}
              />
               {billingError[0].dropLocation =="dropLocation"&& <p className="text-danger">Please Enter Valid Drop Location </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5">
              Trip Km
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={billing[0].km}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].km = e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '');
                  setBilling(value);
                }}
                disabled={billingDetails?.customer_id}
              />
              {billingError[0].km =="km"&& <p className="text-danger">Please Enter Valid KM </p>}
            </div>
            </div>
         <div className="row form-group">
            <label for="exampleInput" className="text-black fs-5">
              A/C Vehicle 
            </label>
            <div className="d-flex">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                // name="inlineRadioOptions"
                id="1"
                onChange={(e) => {
                  setBilling([{ ...billing[0], ac_vehicle: 1 }]);
        }}
        checked={billing[0].ac_vehicle == 1}
              />
              <label className="form-check-label" for="inlineRadio1">
                A/C
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                // name="inlineRadioOptions"
                id="0"
                onChange={(e) => {
                  setBilling([{ ...billing[0], ac_vehicle: 0 }]);
                }}
                checked={billing[0].ac_vehicle == 0}
              />
              <label className="form-check-label" for="inlineRadio2">
                Non A/C
              </label>
            </div>
            </div>
            </div>
            <div className='row'>
            <div className="form-group  col-md-6 ">
              <label for="exampleInputPassword4" className="text-black fs-5">
                Payment Type
              </label>
              <select
                className="form-control fs-6 form-select p-1"
                id="exampleSelectGender"
                value={billing[0].paymentType}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].paymentType = e.target.value;
                  setBilling(value);
                }}
              >
                <option value=""> Select</option>
                <option value="Online"> Online</option>
                <option value="Cash"> Cash</option>
                <option value="NoPayment"> No Payment</option>
              </select>
              {billingError[0].paymentType =="paymentType"&& <p className="text-danger">Please Select Valid Payment Type </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputPassword4" className="text-black fs-5">
                Billing Amount
              </label>
              <input
                type="text"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={billing[0].billAmount}
                onChange={(e) => {
                  let value = [...billing];
                  value[0].billAmount = e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '');
                  setBilling(value);
                }}
              />
               {billingError[0].billAmount =="billAmount"&& <p className="text-danger">Please Enter Billing Amount </p>}
            </div>
            </div>
             {billing[0].paymentType == "Cash" &&(<div className="form-group">
              <label for="exampleInputPassword4" className="text-black fs-5" >
                Advance Amount
              </label>
              <input
                type="text"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={billing[0].advanceAmount}
                onChange={(e) => {
                  let value = [...billing];
                //  const number= value[0].advanceAmount =e.target.value;
                 value[0].advanceAmount=e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '');
                  setBilling(value);
                }}
               />
              {billingError[0].advanceAmount =="advance"&& <p className="text-danger">Please Enter Valid Advance Amount </p>} 
            </div>) }
            {billing[0].paymentType == "Online" &&(<div className="form-group">
              <label for="exampleInputPassword4" className="text-black fs-5" >
                Advance Amount
              </label>
              <input
                type="text"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={billing[0].advanceAmount}
                onChange={(e) => {
                  let value = [...billing];
                //  const number= value[0].advanceAmount =e.target.value;
                value[0].advanceAmount=e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '');
                  setBilling(value);
                }}
               />
                {billingError[0].advanceAmount =="advance"&& <p className="text-danger">Please Enter Valid Advance Amount </p>} 
            </div>) }
            <div className="row form-group">
            <label for="exampleInputPassword4" className="text-black fs-5">
              GST Bill
            </label>
            <div className="d-flex ">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                onChange={(e) => {
                  let value = [...billing];
                  value[0].gst = "with";
                  setBilling(value);
                }}
                checked={billing[0]?.gst === "with"}
              />
              <label className="form-check-label" for="inlineRadio1">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                onChange={(e) => {
                  let value = [...billing];
                  value[0].gst = "without";
                  setBilling(value);
                }}
                checked={billing[0]?.gst === "without"}
              />
              <label className="form-check-label" for="inlineRadio2">
                No
              </label>
            </div>
            </div>
            {billingError[0].gst =="gst"&& <p className="text-danger">Please Select Bill Type </p>} 
            </div>
           
            <div className="form-group  ">
              <label for="exampleInputPassword4" className="text-black fs-5">
               Booking Notes
              </label>
              <input
                type="text"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={billing[0].notes}
                onChange={(e) => {
                  let value = [...billing];
                 const number=e.target.value;
                 value[0].notes=number;
                  setBilling(value);
                }}
              />
               {/* {billingError[0].paymentType =="paymentType"&& <p className="text-danger">Please Enter Billing Amount </p>} */}
            </div>
            {billing[0].bookingDate && billing[0].droppingTime && <h4 className="text-success">Trip Total Hours [hours:Minutes] = {Math.floor(Math.floor((new Date(billing[0].droppingTime) - new Date(billing[0].bookingDate)) / (1000 * 60))/60) + ":" +
 Math.floor(((new Date(billing[0].droppingTime) - new Date(billing[0].bookingDate)) % (1000 * 60 * 60)) / (1000 * 60).toFixed(2))}</h4>} 
  <h1 className="page-title text-center fs-1 mb-2">{billingDetails?.item?.booking_id ? "Booking Update Expenses" : "Booking Expenses"}</h1>
           <div className='row'>
            <div className="form-group  col-md-6">
              <label for="exampleInputEmail3" className="text-black fs-5">
                Diesel Expenses
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={bookingExpenses[0].diesel}
                onChange={(e) => {
                  let value = [...bookingExpenses];
                  value[0].diesel = e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '');
                  setBookingExpenses(value);
                }}
              />
               {billingError[0].pickupLocation =="pickup"&& <p className="text-danger">Please Enter Valid Pickup Location </p>}
            </div>
            <div className="form-group  col-md-6">
              <label for="exampleInputPassword4" className="text-black fs-5">
                Driver Bata & Extra
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputPassword4"
                value={bookingExpenses[0].driverBata}
                onChange={(e) => {
                  let value = [...bookingExpenses];
                  value[0].driverBata = e.target.value.replace(/[eE]/g, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '').replace(/^0/, '').replace(/[^0-9]|(?<=\d)-(?=\d)/g, '');
                  setBookingExpenses(value);
                }}
              />
               {billingError[0].dropLocation =="dropLocation"&& <p className="text-danger">Please Enter Valid Drop Location </p>}
            </div>
            </div>
            <div className="form-group  ">
              <label for="exampleInputPassword4" className="text-black fs-5">
              Expenses  Notes
              </label>
              <input
                type="text"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={bookingExpenses[0].expensesNote}
                onChange={(e) => {
                  let value = [...bookingExpenses];
                 const number=e.target.value;
                 value[0].expensesNote=number;
                 setBookingExpenses(value);
                }}
              />
               {/* {billingError[0].paymentType =="paymentType"&& <p className="text-danger">Please Enter Billing Amount </p>} */}
            </div>
            <div className="d-flex col-12  justify-content-center">
              <div className="col-4">
                <button
                  // type="submit"
                  onClick={buttonDisable?"":() =>bookingForm()}
                  className="btn btn-primary mr-2"
                  disabled={buttonDisable ? "disabled" : ""}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCreate;
