import React from 'react';
import {
    MaterialReactTable,
    createMRTColumnHelper,
    useMaterialReactTable,
  } from 'material-react-table';
  import { Box, Button } from '@mui/material';
  import FileDownloadIcon from '@mui/icons-material/FileDownload';

  import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
  import { jsPDF } from 'jspdf'; 
  import autoTable from 'jspdf-autotable';

import { useEffect, useState } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { GrValidate } from "react-icons/gr";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FcApproval } from "react-icons/fc";

import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CustomerCreation from '../../Forms/CustomerCreation';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import {api} from'../../api'
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


  
  const CustomerReport = () => {
    
    const [data,setData]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [editCustomer,setCustomer]=useState();
    const[customerDetail,setCustomerDetail]=useState([]);
    const[updateData,setUpdateData]=useState();
    const[isActiveCustomer,setIsActiveCustomer]=useState();
    const [editData,setEditData]=useState({});
    const [pagination, setPagination] = useState({
  pageIndex: 0,
  pageSize: 5, //customize the default page size
});


  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data)
  }
  const handleClose = () => setOpen(false)

  let navigate = useNavigate();

    const handleEdit = (rowData) => {
    //  console.log(rowData);
     setCustomer(rowData);
    };
    // console.log(editCustomer?.item?.customer_id)

    const findCustomer=async()=>{
      if(editCustomer?.item?.customer_id){
        const id=editCustomer.item.customer_id;
      const customer=await axios.get(`${api.api}/customer/oneCustomer`,{params:{id}});
      // console.log(customer);
      setCustomerDetail(customer.data.data);
      }else{
        // console.log(editCustomer);
      }
    }


    const handleDelete = async(rowData) => {
      // console.log(rowData.item.is_active)
      const status=rowData.item.is_active == true? false :true;
      setIsActiveCustomer(status);
      const id=rowData.item.customer_id;
      const customerActive=await axios.post(`${api.api}/customer/isActiveCustomer`,{status,id});
      // console.log(customerActive);
      setCustomerDetail(rowData.item.customer_id);
    };

    const customerData=async()=>{
      const apiData=await axios.get(`${api.api}/customer/allCustomer`);
      const value=apiData.data.customer;
      const database=value.map(item=>{
        return ({item,'reward':item?.rewards[0] ||[]})
      })
      setData(database);
    }

    const handleRewarded=async(id)=>{
      const check=(localStorage.getItem("login"));
      // console.log(check.toLowerCase().includes("regular"));
      if(check.toLowerCase().includes("regular")){
      const rewardId=id;
      // console.log(rewardId);
      const apiData=await axios.post(`${api.api}/reward/rewarded`,{rewardId});
      setUpdateData(rewardId);
      alert("Reward Give Successfully ")
      }else{
        alert("Reward Give Only for Authorized Person");
      }
    }
    const columnHelper = createMRTColumnHelper();
  
    const columns = [
          // columnHelper.accessor('item.customer_id', {
          //   header: 'ID',
          //   size: 10,
          // }),
          columnHelper.accessor('item.customer_name', {
            header: 'Name',
            size: 20,
          }),
          columnHelper.accessor('item.phone_number', {
            header: 'Mobile No',
            size: 20,
          }),
          columnHelper.accessor('item.address', {
            header: 'Address',
            size: 20,
          }),
          columnHelper.accessor('item.city', {
            header: 'City',
            size: 20,
          }),
          columnHelper.accessor('reward.total_points', {
            header: 'Points',
            size: 10,
          }),
          columnHelper.accessor('item.is_active',{
            accessor: 'reward',
            header: 'Reward',
            size: 10,
            Cell:({row},rowIndex) => {
              // console.log(row.original.item);
              return(
                <Box> 
                <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Delete">
                {row.original?.reward.total_points >0 ?<FcApproval className='fs-6' onClick={()=>{handleRewarded(row.original?.reward.reward_id)}} />:<GrValidate  className='text-white fs-6 bg-danger'/>}
                </IconButton>
              </Box>)
              }
            }),
          columnHelper.accessor('item',{
            accessor: 'action',
            header: 'Action',
            size: 40,
            Cell:({row},rowIndex) => {
              // console.log(row);
              return(
                <Box>
               <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="View" >
                <VisibilityIcon className='text-primary fs-6' onClick={()=>handleOpen(row)} />
                </IconButton>
            
                 {/* } */}
                {row.original.item.is_active === true && <IconButton className='fs-6' variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => handleEdit(row.original)}>
                <EditIcon className='fs-6' />
                </IconButton>}
                <IconButton className='fs-4'  variant="outlined" data-toggle="tooltip" data-placement="bottom" title={row.original.item.is_active === true?"Delete":"Active"} onClick={() => handleDelete(row.original)}>
                {row.original.item.is_active === true ?<CancelIcon className='text-danger fs-6'/>:<CheckCircleIcon className='text-success fs-6'/>}
                </IconButton>
              </Box>)
              }
            }),
        ];
          
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

    const handleExportRows = (rows) => {
      const rowData = rows.map((row) => row.original);

      const values=rowData.map(item=>{
        return({
          "Customer ID": item.item.customer_id,
          "Customer Name": item.item.customer_name,
          "Customer MobileNo": item.item.phone_number,
          "Customer Address": item.item.address,
          "Customer City": item.item.city,
          "Customer Active Status": item.item.is_active,
          // "createdAt": item.item.createdAt,
          // "updatedAt": item.item.updatedAt,
          // "reward_id": item.reward_id,
          // "customer_id": item.reward.customer_id,
          "Customer Total No.Of.Travel": item.reward.total_no_of_travel,
          "Customer Total Amount": item.reward.total_amount,
          "Customer Reward Point": item.reward.total_points,
          //  "is_reward": item.reward.is_reward,
          //  "is_active": item.reward.is_active,
          //  "createdAt": item.reward.createdAt,
          //  "updatedAt": item.reward.updatedAt
          })
          })

      const csv = generateCsv(csvConfig)(values);
      download(csvConfig)(csv);
    };
  
    const handleExportData = () => {
      
      const values=data.map(item=>{
        return({
          "Customer ID": item.item.customer_id,
          "Customer Name": item.item.customer_name,
          "Customer MobileNo": item.item.phone_number,
          "Customer Address": item.item.address,
          "Customer City": item.item.city,
          "Customer Active Status": item.item.is_active,
          // "createdAt": item.item.createdAt,
          // "updatedAt": item.item.updatedAt,
          // "reward_id": item.reward_id,
          // "customer_id": item.reward.customer_id,
          "Customer Total No.Of.Travel": item.reward.total_no_of_travel,
          "Customer Total Amount": item.reward.total_amount,
          "Customer Reward Point": item.reward.total_points,
          //  "is_reward": item.reward.is_reward,
          //  "is_active": item.reward.is_active,
          //  "createdAt": item.reward.createdAt,
          //  "updatedAt": item.reward.updatedAt
          })
          })
      const csv = generateCsv(csvConfig)(values);
      download(csvConfig)(csv);
    };
  
    const handleExportRowsPDF = (rows) => {
      const rowData = rows.map((row) => row.original);
      const value=rowData.map(item=>{
        return({
          "Customer ID": item.item.customer_id,
          "Customer Name": item.item.customer_name,
          "Customer MobileNo": item.item.phone_number,
          "Customer Address": item.item.address,
          "Customer City": item.item.city,
          // "Customer Active Status": item.item.is_active,
          // "createdAt": item.item.createdAt,
          // "updatedAt": item.item.updatedAt,
          // "reward_id": item.reward_id,
          // "customer_id": item.reward.customer_id,
          // "Customer Total No.Of.Travel": item.reward.total_no_of_travel,
          // "Customer Total Amount": item.reward.total_amount,
          "Customer Reward Point": item.reward.total_points,
          //  "is_reward": item.reward.is_reward,
          //  "is_active": item.reward.is_active,
          //  "createdAt": item.reward.createdAt,
          //  "updatedAt": item.reward.updatedAt
          })
          })
      // const doc = new jsPDF();
      const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape
      });
      const tableData = value.map((row) => Object.values(row));
      const tableHeaders = value.length > 0 ? Object.keys(value[0]) : [];
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
      // console.log(rowData);
  
      doc.save('Customer_Report.pdf');
    };



    const table = useMaterialReactTable({
      columns,
      data,
      enableRowNumbers: true,
      rowNumberDisplayMode: 'original',
      enableFullScreenToggle: false,
      // enableRowSelection: true,
      columnFilterDisplayMode: 'popover',
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      enableStickyHeader: true,
      enableGlobalFilter: false,
      rowPinningDisplayMode: 'select-sticky',
      // getRowId: (row) => row.item.phone_number,
      initialState: { pagination: { pageSize: 100, pageIndex: 0, }, density: 'compact'},
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
          All
          </Button>
          {/* <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
          >
            Export All Rows
          </Button> */}
          <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
          Filter
          </Button>
          <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRowsPDF(table.getPrePaginationRowModel().rows)
          }
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
         All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
        Filter
        </Button>
          <Button>
          <Link to="/home/customerCreation">Create</Link>
          </Button>
        </Box>
      ),
    });
  

    
    useEffect(()=>{
      customerData();
    },[]);

    useEffect(()=>{
      customerData();
    },[customerDetail]);

    useEffect(()=>{
      customerData();
    },[editCustomer]);


    useEffect(()=>{
      findCustomer();
      if(editCustomer ==undefined){
      navigate('/home/customerReport')
      }
    },[editCustomer]);

    useEffect(()=>{
      customerData();
    },[updateData]);

     useEffect(()=>{
      customerData();
    },[isActiveCustomer]);

    
    useEffect(()=>{
      // if(window.location.pathname =="/home/customerReport"){
      //   setCustomer();
      // }
    },[])
    return( 
      <div>
       {editCustomer?<div><div className='d-flex col-12 justify-content-end'><button className='btn btn-primary col-2'onClick={()=>{setCustomer()}} >Back</button></div><CustomerCreation customerDetail={customerDetail} back={setCustomer}/> </div>:<div><h1 className='text-center'>Customers</h1><MaterialReactTable table={table} /></div>}
      {open && <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
               
            <Box sx={style}>
            <DialogContent>
            <button
          onClick={handleClose}
          className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
        >
          X
        </button>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Customer name"
            type="text"
            fullWidth
            defaultValue={editData.original.item.customer_name}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="mobile"
            label="Customer Mobile No"
            type="email"
            fullWidth
            defaultValue={editData.original.item.phone_number}
            InputProps={{
              readOnly: true,
            }}
          />
           <TextField
            margin="dense"
            id="mobile"
            label="Customer Address"
            type="address"
            fullWidth
            defaultValue={editData.original.item.address}
            InputProps={{
              readOnly: true,
            }}
          />
           <TextField
            margin="dense"
            id="mobile"
            label="Customer City"
            type="city"
            fullWidth
            defaultValue={editData.original.item.city}
            InputProps={{
              readOnly: true,
            }}
          />
          
        </DialogContent>
           </Box>
              </Modal>}

        </div>
    );
  };
  
  export default CustomerReport;
  