import React from 'react';
import {
    MaterialReactTable,
    createMRTColumnHelper,
    useMaterialReactTable,
  } from 'material-react-table';
  import { Box, Button } from '@mui/material';
  import FileDownloadIcon from '@mui/icons-material/FileDownload';

  import { mkConfig, generateCsv, download } from 'export-to-csv'; 
  import { jsPDF } from 'jspdf'; 
  import autoTable from 'jspdf-autotable';

import { useEffect, useState } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { FaRegCalendarAlt } from "react-icons/fa";

import Stack from '@mui/material/Stack';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomerCreation from '../../Forms/CustomerCreation';
import VendorCreation from '../../Forms/VendorCreation';
import VehicleCreation from '../../Forms/VehicleCreation';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import {api} from'../../api'
import { MdOutlinePictureAsPdf } from "react-icons/md";
import {useNavigate } from 'react-router-dom';
import VehicleAttendance from '../../Attendance/VehicleAttendance';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


  
  const RentVehicleReport = () => {
    
    const [data,setData]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [editVehicle,setVehicle]=useState();
    const[vehicleDetail,setVehicleDetail]=useState([]); 
    const [attendanceDetail,setAttendanceDetail]=useState();
    const [editData,setEditData]=useState({});
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 5, //customize the default page size
      density: 'compact',
    });

  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data)
  }
  const handleClose = () => setOpen(false)

  
  let navigate = useNavigate();

    const handleEdit = (rowData) => {
    //  console.log(rowData);
    setAttendanceDetail("");
     setVehicle(rowData);
     setVehicleDetail(rowData);
    };
    // console.log(editVehicle?.item?.customer_id)

    // const findVendor=async()=>{
    //   if(editVehicle?.vendor_id){
    //     const id=editVehicle.vendor_id;
    //   const vendor=await axios.get(`${api.api}/vendor/findOneVendor`,{params:{id}});
    //   console.log(vendor);
    //   setVehicleDetail(vendor.data.vendor);
    //   }else{
    //     // console.log(editVehicle);
    //   }
    // }


    const handleDelete = async(rowData) => {
      const status=rowData.is_active == true? false :true;
      const id=rowData.vehicle_id;
      setVehicleDetail(status);
      // console.log(rowData);
      // console.log(id);
      // console.log(status);
      const vehicleActive=await axios.post(`${api.api}/vehicle/isActiveVehicle`,{status,id});
      // console.log(vehicleActive);
      setVehicleDetail(rowData.vendor_id);
    };

    const vehicleData=async()=>{
      const apiData=await axios.get(`${api.api}/vehicle/allRentVehicle`);
      const apiAttendance=await axios.get(`${api.api}/vehicle/rentVehicleSumAttendance`);
      // console.log(apiAttendance.data?.vehicle);
      const attendance=apiAttendance.data?.vehicle;
      const value=apiData.data.vehicle;

      const attendanceMap = new Map(attendance.map(entry => [entry.vehicle_id, entry]));

      // Combine data from both API calls
      const combinedData = value.map(vehicle => {
        const attendanceData = attendanceMap.get(vehicle.vehicle_id) || { totalStatus: '0' };
      
        return {
          createdAt: vehicle?.createdAt,
          fc: vehicle?.fc,
          insurance: vehicle?.insurance,
          is_active: vehicle?.is_active,
          updatedAt: vehicle?.updatedAt, // Fix: Remove the extra dot
          vehicle_id: vehicle?.vehicle_id,
          vehicle_model: vehicle?.vehicle_model,
          vehicle_number: vehicle?.vehicle_number,
          vehicle_type: vehicle?.vehicle_type,
          pollution:vehicle?.pollution,
          vehicle_attendances: {
            vehicle_id: vehicle.vehicle_id,
            totalStatus: attendanceData.totalStatus,
          },
        };
      });
      
      // setData is assumed to be a function to update the state or perform some action
      setData(combinedData);
      
      
      
      // console.log(value);
    //   const database=value.map(item=>{
    //     return ({item,'reward':item?.rewards[0] ||[]})
    //   })
      setData(combinedData);
      // console.log(value);
    }

    const handleCalendar=async(e)=>{
        // console.log(e);
        navigate(`/home/RentVehicleUpdate/Attendance`)
        setAttendanceDetail(e);
      setVehicle(e);
      setVehicleDetail(e);
    }

    // console.log(data);
    const columnHelper = createMRTColumnHelper();
  
    const columns = [
          // columnHelper.accessor('vehicle_id', {
          //   header: 'ID',
          //   size: 40,
          // }),
          columnHelper.accessor('vehicle_number', {
            header: 'Vehicle NO',
            size: 40,
          }),
          columnHelper.accessor('vehicle_model', {
            header: 'Model',
            size: 40,
          }),
          columnHelper.accessor('vehicle_type', {
            header: 'Type',
            size: 40,
          }), 
          columnHelper.accessor('insurance', {
            id: 'Insurance', // Unique ID for the column
            header: 'Insurances', // Header title for the column
            filterVariant: 'date-range', // Filter variant for filtering dates by range
            size: 20, // Size or width of the column (assuming in pixels)
            accessorFn: (originalRow) => new Date(originalRow.insurance), // Function to convert to Date for sorting and filtering
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // Function to format cell value as localized date string
          }),
          columnHelper.accessor('fc', {
            id: 'FC', // Unique ID for the column
            header: 'FC', // Header title for the column
            filterVariant: 'date-range', // Filter variant for filtering dates by range
            size: 20, // Size or width of the column (assuming in pixels)
            accessorFn: (originalRow) => new Date(originalRow.fc), // Function to convert to Date for sorting and filtering
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // Function to format cell value as localized date string
          }),
          columnHelper.accessor('pollution', {
            id: 'Pollution Date', // Unique ID for the column
            header: 'Pollution Date', // Header title for the column
            filterVariant: 'date-range', // Filter variant for filtering dates by range
            size: 20, // Size or width of the column (assuming in pixels)
            accessorFn: (originalRow) => originalRow?.pollution ? new Date(originalRow?.pollution) : 0, // Function to convert to Date for sorting and filtering
            Cell: ({ cell }) => {
                if (!cell || !cell.getValue()) return undefined; // Check if cell or cell value is null
                return cell.getValue().toLocaleDateString();
            },
        }),
          columnHelper.accessor('vehicle_attendances',{
            accessor: 'action',
            header: 'Attendance',
            size: 40,
            Cell:({row},rowIndex) => {
              // console.log(row);
              return(
                <Box> 
        <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{Number(row.original?.vehicle_attendances?.totalStatus)/2}</Typography>
        <IconButton onClick={()=>{handleCalendar(row.original)}}><FaRegCalendarAlt className='text-secondary'  inputProps={{ 'aria-label': 'ant design' }} /></IconButton>
        {/* <Typography></Typography> */}
      </Stack>
              </Box>)
              }
            }),
          columnHelper.accessor('is_active',{
            accessor: 'action',
            header: 'Action',
            size: 40,
            Cell:({row},rowIndex) => {
              // console.log(row);
              return(
                <Box key={row.original.vehicle_id}>
               {/* {row.original.item.is_active === true &&  */}
               <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="View" >
                <VisibilityIcon className='text-primary fs-6' onClick={()=>handleOpen(row)} />
                </IconButton>
                 {/* } */}
                {row.original.is_active === true && <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => handleEdit(row.original)}>
                <EditIcon className='fs-6'/>
                </IconButton>}
                <IconButton  variant="outlined" data-toggle="tooltip" data-placement="bottom" title={row.original.is_active == true?"Delete":"Active"} onClick={() => handleDelete(row.original)}>
                {row.original.is_active === true ?<CancelIcon className='text-danger fs-6'/>:<CheckCircleIcon className='text-success fs-6'/>}
                </IconButton>
              </Box>)
              }
            }),
        ];
          
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

    const handleExportRows = (rows) => {
      const value = rows.map((row) => ({
        "Vehicle ID": row.original.vehicle_id,
        "Vehicle Number": row.original.vehicle_number,
        "Vehicle Model": row.original.vehicle_model,
        "Vehicle Type": row.original.vehicle_type,
        "Insurance": new Date(row.original.insurance).toLocaleDateString('en-GB'),
        "FC": new Date(row.original.fc).toLocaleDateString('en-GB'),
        'Pollution Date':row.original.pollution?new Date(row.original.pollution).toLocaleDateString('en-GB'):"",
      }));

      const rowData = value.map((row) => row);
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    };
  
    const handleExportData = () => {
      const value = data.map((row) => ({
        "Vehicle ID": row.vehicle_id,
        "Vehicle Number": row.vehicle_number,
        "Vehicle Model": row.vehicle_model,
        "Vehicle Type": row.vehicle_type,
        "Insurance": new Date(row.insurance).toLocaleDateString('en-GB'),
        "FC": new Date(row.fc).toLocaleDateString('en-GB'),
        'Pollution Date':row.original.pollution?new Date(row.original.pollution).toLocaleDateString('en-GB'):"",
      }));
      const csv = generateCsv(csvConfig)(value);
      download(csvConfig)(csv);
    };


    const handleExportRowsPDF = (rows) => {
      const doc = new jsPDF();
      const value = rows.map((row) => ({
        "Vehicle ID": row.original.vehicle_id,
        "Vehicle Number": row.original.vehicle_number,
        "Vehicle Model": row.original.vehicle_model,
        "Vehicle Type": row.original.vehicle_type,
        "Insurance": new Date(row.original.insurance).toLocaleDateString('en-GB'),
        "FC": new Date(row.original.fc).toLocaleDateString('en-GB'),
        'Pollution Date':row.original.pollution?new Date(row.original.pollution).toLocaleDateString('en-GB'):"",
        // "Is Active": row.original.is_active,
        // "Created At": row.original.createdAt,
        // "Updated At": row.original.updatedAt
      }));

      const tableData = value.map((row) => Object.values(row));
      const tableHeaders = value.length > 0 ? Object.keys(value[0]) : [];
    
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
    
      // console.log(tableData);
      // console.log(tableHeaders);
    
      doc.save('Vehicle_Report.pdf');
    };

    const table = useMaterialReactTable({
      columns,
      data,
      enableRowNumbers: true,
      rowNumberDisplayMode: 'original',
      enableFullScreenToggle: false,
      // enableRowSelection: true,
      columnFilterDisplayMode: 'popover',
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      enableGlobalFilter: false,
      enableStickyHeader: true,
      enableGlobalFilter: false,
      initialState: { pagination: { pageSize: 100, pageIndex: 0, }, density: 'compact' },
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
            Export
          </Button>
          <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<BackupTableTwoToneIcon className='text-success' />}
          >
          Filter
          </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRowsPDF(table.getPrePaginationRowModel().rows)
          }
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
         All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className='text-danger '/>}
        >
        Filter
        </Button>
          <Button>
          <Link to="/home/vehicleCreation">Create</Link>
          </Button>
        </Box>
      ),
    });
  // 

    // console.log(vehicleDetail);
    useEffect(()=>{
      vehicleData();
    },[]);

    useEffect(()=>{
      vehicleData();
    },[vehicleDetail]);
    useEffect(()=>{
        vehicleData();
      },[vehicleDetail])
    
    useEffect(()=>{
      if(editVehicle == undefined){
        navigate('/home/RentVehicleReport')
      }
    },[editVehicle]);
    // console.log(attendanceDetail);
    // console.log(editVehicle);
    return( 
      <div>
       {editVehicle?<div>
        <div className='d-flex col-12 justify-content-end'>
          {/* <button className='btn btn-primary col-2'onClick={()=>{setVehicle()}} >Back</button> */}
       </div>{attendanceDetail.vehicle_id?<VehicleAttendance vehicle={attendanceDetail} />:<VehicleCreation vehicleDetail={vehicleDetail} setVehicleDetail={setVehicleDetail} back={setVehicle}/> }</div>:<div ><h1 className='text-center'>Rent Vehicle</h1><LocalizationProvider dateAdapter={AdapterDayjs}><MaterialReactTable table={table} /></LocalizationProvider></div>}
       {open &&  <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
            <Box sx={style}>
            <DialogContent>
            <button
          onClick={handleClose}
          className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
        >
          X
        </button>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Vehicle Number"
            type="text"
            fullWidth
            defaultValue={editData.original.vehicle_number}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="mobile"
            label="Vehicle Model"
            type="email"
            fullWidth
            defaultValue={editData.original.vehicle_model}
            InputProps={{
              readOnly: true,
            }}
          />
          
           <TextField
            margin="dense"
            id="mobile"
            label="Vehicle Type"
            type="city"
            fullWidth
            defaultValue={editData.original.vehicle_type}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
           </Box>
              </Modal>
             }

        </div>
    );
  };
  
  export default RentVehicleReport;
  