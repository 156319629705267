import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import axios from "axios";
import React, { useEffect } from 'react';
import { utils, writeFileXLSX } from "xlsx";
import * as XLSX from 'xlsx'; 

import jsPDF from 'jspdf';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
  import {
    IconButton,
    Tooltip,
  } from '@mui/material';
  import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
  import { MdOutlinePictureAsPdf } from "react-icons/md";
  import {api} from'../../api';



const OwnConsolidateReport = () => {
  const [data,setData]=useState([]);
  const [reportDate,setReportDate]=useState(new Date());


  const fetchData = async () => {
    try {
        const database = await axios.get(`${api.api}/booking/OwnConsolidateReport`,{params:{reportDate}});
        const value = database?.data;
        // console.log(value);

      const calculateBookingTotalValue = (booking) => {
        // console.log(booking);
        const bookingAmount = Number(booking.bill_amount) - Number(booking.payments[0]?.sgst || 0) * 2;
        // console.log(bookingAmount);
        return bookingAmount;
      };
      
      const calculatePaymentTotal = (payment) => {
        const paymentAmount = Number(payment.our_amount);
        return paymentAmount;
      };
      
      const calculateExpensesTotal = (expenses) => {
        const totalExpenses = Number(expenses?.expenses_amount || 0);
        return totalExpenses;
      };
      
      // const calculateVendorPaymentTotal = (vendor) => {
      //   const totalVendorPayment = Number(vendor?.paid ? vendor.paid : 0);
      //   return totalVendorPayment;
      // };
      
      const bookingTotalValues = value.Booking.map(booking => {
        return calculateBookingTotalValue(booking);
      });
      
      const paymentTotalValues = (value.todayPayment || []).map(payment => {
        return calculatePaymentTotal(payment);
      });
      
      const expensesTotalValues = (value.todayExpenses || []).map(expenses => {
        return calculateExpensesTotal(expenses);
      });
      
      // const vendorPaymentTotalValues = (value.vendorPayment || []).map(vendor => {
      //   return calculateVendorPaymentTotal(vendor);
      // });

      const bookingTotal = bookingTotalValues.reduce((acc, value) => acc + value, 0);
const paymentTotal = paymentTotalValues.reduce((acc, value) => acc + value, 0);
const expensesTotal = expensesTotalValues.reduce((acc, value) => acc + value, 0);
// const vendorPaymentTotal = vendorPaymentTotalValues.reduce((acc, value) => acc + value, 0);

// console.log({ bookingTotal, paymentTotal, expensesTotal });
setData({ bookingTotal, paymentTotal, expensesTotal });
        
       
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const xport = React.useCallback(() => {
    
  const table = document.getElementById("Table2XLSX");
  const wb = XLSX.utils.table_to_book(table);

  /* Export to file (start a download) */
  XLSX.writeFile(wb, "Consolidate_Report.xlsx");
});

const generatePDF = React.useCallback(() => {
  const table = document.getElementById("Table2XLSX");
  const wb = XLSX.utils.table_to_book(table);
  
  // Generate PDF
  const doc = new jsPDF();
  doc.text(20, 20, `Consolidated Report as on ${new Date(reportDate).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}`);

  // Convert workbook to PDF
  wb.SheetNames.forEach(sheetName => {
    const s = wb.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(s, { header: 1 });
    // doc.text(20, 30, sheetName);
    doc.autoTable({ startY: 40, html: '#Table2XLSX' });
  });

  // Save the PDF
  doc.save("Consolidate_Report.pdf");
}, []);

  //  console.log(data);
  
  useEffect(()=>{
    fetchData();
  },[])

    useEffect(()=>{
    fetchData();
  },[reportDate])

//   .card {
//     ;
// }


  return (
<div className="page-content page-container" id="page-content">
    <div className="padding">
        <div className="row container d-flex justify-content-center">
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body m-0 p-3" style={{boxShadow:"0px 0px 19px 6px gray"}}>
                        <div className="row">
                            <div className="col-md-10">
                                <h2 className="fs-2 text-center">Own - Consolidate Report</h2>
                                <p className="card-description d-flex align-items-center fs-5 text-dark"> Report Date: 
                                <DatePicker
      className="form-control fs-6"
      selected={reportDate}
      onChange={(date) =>{ setReportDate(date)}}
      // showTimeSelect
      // timeFormat="HH:mm"
      // timeIntervals={15}
      // timeCaption="time"
      dateFormat="dd,MMMM, yyyy "
      // minDate={new Date()}
      // withPortal
    /></p>
                            </div>
                            <div className="col-md-2 text-right"> <IconButton variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Export to Excel"  onClick={xport}><BackupTableTwoToneIcon className='text-success'/></IconButton> 
                            <IconButton variant="outlined" data-toggle="tooltip" data-placement="bottom"  onClick={generatePDF} title="Export to PDF"> <MdOutlinePictureAsPdf  className='text-danger'/></IconButton> </div>
                        </div>
                        <div className="table-responsive">
                            <table  className="table" id="Table2XLSX">
                                <thead>
                                    <tr>
                                        <th className='fs-5'>Particulars</th>
                                        <th className='fs-5'>Debit Rs.</th>
                                        <th className='fs-5'>Credit Rs.</th>
                                        <th className='fs-5'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='fs-6'>Booking Amount</td>
                                        <td className='fs-6'></td>
                                        <td className='fs-6'>{data?.bookingTotal}</td>
                                        <td className='fs-6'></td>
                                    </tr>
                                    <tr>
                                        <td className='fs-6'>Booking Amount Received</td>
                                        <td className='fs-6'>{data?.paymentTotal}</td>
                                        <td className='fs-6'></td>
                                        <td className='fs-6'></td>
                                    </tr>
                                    <tr className='bg-secondary' >
                                        <td  className="fs-5 text-white bg-dark">Gross Income</td>
                                        <td className='fs-5 text-white bg-dark'></td>
                                        <td className='fs-5 text-white bg-dark'></td>
                                        <td  className="fs-5 text-white bg-dark" >{Number(data?.paymentTotal?data?.paymentTotal:0)-Number(data?.bookingTotal?data?.bookingTotal:0)}</td>
                                    </tr>
                                    <tr>
                                        <td className='fs-5'>Vendor Payment</td>
                                        <td className='fs-5'>{data?.vendorPaymentTotal}</td>
                                        <td className='fs-5'></td>
                                        <td className='fs-5'><label className="badge badge-success"></label></td>
                                    </tr>
                                    <tr>
                                        <td className='fs-5'>Expenses Payment</td>
                                        <td className='fs-5'>{data?.expensesTotal}</td>
                                        <td className='fs-5'></td>
                                        <td className='fs-5'></td>
                                    </tr>
                                    <tr>
                                            <td  className="fs-5 text-white bg-dark">Gross Expenses</td>
                                            <td className="fs-5 text-white bg-dark"></td>
                                            <td className="fs-5 text-white bg-dark"></td>
                                            <td  className="fs-5 text-white bg-dark" >{Number(data?.vendorPaymentTotal?data?.vendorPaymentTotal:0)+Number(data?.expensesTotal?data?.expensesTotal:0)}</td>
                                        </tr>
                                        <tr>
                                        <td  className="fs-5"> Balance Amount <p>Amount Received - Vendor Payment - Expenses </p><p className='d-flex '><p className='text-success'>{Number(data?.paymentTotal)} </p> - <p className='text-danger'>{Number(data?.vendorPaymentTotal?data?.vendorPaymentTotal:0)} </p> - <p className='text-danger'>{Number(data?.expensesTotal)}</p></p></td>
                                        <td></td>
                                        <td></td>
                                        <td  className="fs-5" >{(Number(data?.paymentTotal?data?.paymentTotal:0)-Number(data?.bookingTotalValue? data?.bookingTotalValue :0 ))-(Number(data.vendorPaymentTotal ?data.vendorPaymentTotal :0 )+Number(data?.expensesTotal?data?.expensesTotal:0))}</td>
                                    </tr>
                                    <tr>
                                        <td  className="fs-5"> Day Profit <p>Total Income - Toal Expenses</p><p className='d-flex '><p className='text-success'>{Number(data?.paymentTotal?data?.paymentTotal:0)-Number(data?.bookingTotal?data?.bookingTotal:0)}</p> - <p className='text-danger'>{Number(data?.vendorPaymentTotal?data?.vendorPaymentTotal:0)+Number(data?.expensesTotal?data?.expensesTotal:0)}</p> </p></td>
                                        <td></td>
                                        <td></td>
                                        <td  className="fs-5" >{Number(data?.paymentTotal?data?.paymentTotal:0)-Number(data?.bookingTotal?data?.bookingTotal:0) - (Number(data?.vendorPaymentTotal?data?.vendorPaymentTotal:0)+Number(data?.expensesTotal?data?.expensesTotal:0))}</td>
                                         </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
};

export default OwnConsolidateReport;







// import { useMemo, useState } from 'react';
// import {
//   MaterialReactTable,
//   useMaterialReactTable,
// } from 'material-react-table';
// import axios from "axios";
// import React, { useEffect } from 'react';
// import { utils, writeFileXLSX } from "xlsx";
// import * as XLSX from 'xlsx'; 
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
//   import {
//     IconButton,
//     Tooltip,
//   } from '@mui/material';
//   import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
//   import { MdOutlinePictureAsPdf } from "react-icons/md";
//   import {api} from'../../api';



// const ConsolidateReport = () => {
//   const [data,setData]=useState([]);
//   const [reportDate,setReportDate]=useState(new Date());
//   const [closingBalance,setClosingBalance]=useState([]);


//   const fetchData = async () => {
//     try {
//         const database = await axios.get(`${api.api}/booking/consolidateReport`,{params:{reportDate}});
//         const value = database?.data;
//         console.log(value);

//       //   const totalValue = value.Booking.reduce((accumulator, booking) => {
//       //     const bookingAmount = Number(booking.bill_amount) - Number(booking.payments[0]?.sgst || 0) * 2;
//       //     const bookingTotalValue = bookingAmount + (accumulator || 0);
      
//       //     const totalPayment = value.todayPayment?.reduce((paymentAccumulator, payment) => {
//       //         const paymentAmount = Number(payment.our_amount);
//       //         const paymentTotal = paymentAmount + (paymentAccumulator || 0);
      
//       //         const expensesTotal = value.todayExpenses?.reduce((expensesAccumulator, expenses) => {
//       //             const totalExpenses = Number(expenses?.expenses_amount || 0);
//       //             const expensesTotal = expensesAccumulator + totalExpenses;
      
//       //             // Remove vendorPaymentTotal from here
      
//       //             return expensesTotal;
//       //         }, 0);
      
//       //         // Move vendorPaymentTotal to the outermost level
//       //         const vendorPaymentTotal = value.vendorPayment?.reduce((vendorAccumulator, vendor) => {
//       //             const totalVendorPayment = Number(vendor?.paid ? vendor.paid : 0);
//       //             return vendorAccumulator + totalVendorPayment;
//       //         }, 0);
      
//       //         console.log({ bookingTotalValue, paymentTotal, expensesTotal, vendorPaymentTotal });
//       //         setData({ bookingTotalValue, paymentTotal, expensesTotal, vendorPaymentTotal });
      
//       //         // Return paymentTotal for the outer reduce function
//       //         return paymentTotal;
//       //     }, 0);
      
//       //     // Return bookingTotalValue for the outer reduce function
//       //     return bookingTotalValue;
//       // }, 0);

//       const calculateBookingTotalValue = (booking) => {
//         const bookingAmount = Number(booking.bill_amount) - Number(booking.payments[0]?.sgst || 0) * 2;
//         return bookingAmount;
//       };
      
//       const calculatePaymentTotal = (payment) => {
//         const paymentAmount = Number(payment.our_amount);
//         return paymentAmount;
//       };
      
//       const calculateExpensesTotal = (expenses) => {
//         const totalExpenses = Number(expenses?.expenses_amount || 0);
//         return totalExpenses;
//       };
      
//       const calculateVendorPaymentTotal = (vendor) => {
//         const totalVendorPayment = Number(vendor?.paid ? vendor.paid : 0);
//         return totalVendorPayment;
//       };
      
//       const bookingTotalValues = value.Booking.map(booking => {
//         return calculateBookingTotalValue(booking);
//       });
      
//       const paymentTotalValues = (value.todayPayment || []).map(payment => {
//         return calculatePaymentTotal(payment);
//       });
      
//       const expensesTotalValues = (value.todayExpenses || []).map(expenses => {
//         return calculateExpensesTotal(expenses);
//       });
      
//       const vendorPaymentTotalValues = (value.vendorPayment || []).map(vendor => {
//         return calculateVendorPaymentTotal(vendor);
//       });

//       const bookingTotal = bookingTotalValues.reduce((acc, value) => acc + value, 0);
// const paymentTotal = paymentTotalValues.reduce((acc, value) => acc + value, 0);
// const expensesTotal = expensesTotalValues.reduce((acc, value) => acc + value, 0);
// const vendorPaymentTotal = vendorPaymentTotalValues.reduce((acc, value) => acc + value, 0);

// console.log({ bookingTotal, paymentTotal, expensesTotal, vendorPaymentTotal });
// setData({ bookingTotal, paymentTotal, expensesTotal, vendorPaymentTotal });
        
       
//     } catch (error) {
//         console.error("Error fetching data:", error);
//     }
// };

// const balanceData = async () => {
//   try {
//       const database = await axios.get(`${api.api}/booking/consolidateTotalReport`,{params:{reportDate}});
//       const value = database?.data;
//       console.log(value);

//     const calculateBookingTotalValue = (booking) => {
//       const bookingAmount = Number(booking.bill_amount) - Number(booking.payments[0]?.sgst || 0) * 2;
//       return bookingAmount;
//     };
    
    
//     const bookingTotalValues = value.Booking.map(booking => {
//       return calculateBookingTotalValue(booking);
//     });
  

//     const bookingTotal = bookingTotalValues.reduce((acc, value) => acc + value, 0);

// console.log({ bookingTotal,payment:value.todayPayment[0].todayPayment,expenses:value.todayExpenses[0].totalExpenses,vendor:value.vendorPayment[0].totalExpenses});
// setClosingBalance({ bookingTotal,payment:value.todayPayment[0].todayPayment,expenses:value.todayExpenses[0].totalExpenses,vendor:value.vendorPayment[0].totalExpenses});
// // console.log(value);
// // setData({ bookingTotal, paymentTotal, expensesTotal, vendorPaymentTotal });
      
     
//   } catch (error) {
//       console.error("Error fetching data:", error);
//   }
// };

// const xport = React.useCallback(() => {
    
//   const table = document.getElementById("Table2XLSX");
//   const wb = XLSX.utils.table_to_book(table);

//   /* Export to file (start a download) */
//   XLSX.writeFile(wb, "Consolidate_Report.xlsx");
// });


//    console.log(data);
  
//   useEffect(()=>{
//     fetchData();
//     balanceData();
//   },[])

//     useEffect(()=>{
//     fetchData();
//     balanceData();
//   },[reportDate])

//   console.log(closingBalance);


  
//   // const finalValue=()=>{
//     const booking=Number(Number(closingBalance.bookingTotal?closingBalance.bookingTotal:0)-Number(closingBalance.payment?closingBalance.payment:0))-Number(Number(closingBalance.vendor?closingBalance.vendor:0)+Number(closingBalance.expenses?closingBalance.expenses:0));
//   // }

//   console.log(booking);


//   return (
// <div className="page-content page-container" id="page-content">
//     <div className="padding">
//         <div className="row container d-flex justify-content-center">
//             <div className="col-lg-12 grid-margin stretch-card">
//                 <div className="card">
//                     <div className="card-body">
//                         <div className="row">
//                             <div className="col-md-8">
//                                 <h4 className="card-title">Consolidate Report</h4>
//                                 <p className="card-description"> Report Date: 
//                                 <DatePicker
//       className="form-control fs-6"
//       selected={reportDate}
//       onChange={(date) =>{ setReportDate(date)}}
//       // showTimeSelect
//       // timeFormat="HH:mm"
//       // timeIntervals={15}
//       // timeCaption="time"
//       dateFormat="dd,MMMM, yyyy "
//       // minDate={new Date()}
//       // withPortal
//     /></p>
//                             </div>
//                             <div className="col-md-4 text-right"> <IconButton variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Export to Excel"  onClick={xport}><BackupTableTwoToneIcon className='text-success'/></IconButton> 
//                             <IconButton variant="outlined" data-toggle="tooltip" data-placement="bottom" title="Export to PDF"> <MdOutlinePictureAsPdf  className='text-danger'/></IconButton> </div>
//                         </div>
//                         <div className="table-responsive">
//                             <table  className="table" id="Table2XLSX">
//                                 <thead>
//                                     <tr>
//                                         <th>Particulars</th>
//                                         <th>Debit Rs.</th>
//                                         <th>Credit Rs.</th>
//                                         <th>Amount</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                 <tr>
//                                         <td className="fs-5">Opening Balance</td>
//                                         <td></td>
//                                         <td></td>
//                                         <td className="fs-5">{booking}</td>
//                                     </tr>
//                                     <tr>
//                                         <td>Booking Amount</td>
//                                         <td></td>
//                                         <td>{data?.bookingTotal}</td>
//                                         <td></td>
//                                     </tr>
//                                     <tr>
//                                         <td>Booking Amount Received</td>
//                                         <td>{data?.paymentTotal}</td>
//                                         <td></td>
//                                         <td></td>
//                                     </tr>
//                                     <tr>
//                                         <td  className="fs-5">Gross Income</td>
//                                         <td></td>
//                                         <td></td>
//                                         <td  className="fs-5" >{Number(data?.paymentTotal?data?.paymentTotal:0)-Number(data?.bookingTotal?data?.bookingTotal:0)}</td>
//                                     </tr>
//                                     <tr>
//                                         <td>Vendor Payment</td>
//                                         <td>{data?.vendorPaymentTotal}</td>
//                                         <td></td>
//                                         <td><label className="badge badge-success"></label></td>
//                                     </tr>
//                                     <tr>
//                                         <td>Expenses Payment</td>
//                                         <td>{data?.expensesTotal}</td>
//                                         <td></td>
//                                         <td></td>
//                                     </tr>
//                                     <tr>
//                                         <td  className="fs-5">Gross Expenses</td>
//                                         <td></td>
//                                         <td></td>
//                                         <td  className="fs-5" >{Number(data?.vendorPaymentTotal?data?.vendorPaymentTotal:0)+Number(data?.expensesTotal?data?.expensesTotal:0)}</td>
//                                     </tr>
//                                     <tr>
//                                         <td  className="fs-5"> Closing Balance</td>
//                                         <td></td>
//                                         <td></td>
//                                         <td  className="fs-5" >{Number(Number(Number(data?.paymentTotal?data?.paymentTotal:0)-Number(data?.bookingTotal?data?.bookingTotal:0))-Number(Number(data?.vendorPaymentTotal?data?.vendorPaymentTotal:0)+Number(data?.expensesTotal?data?.expensesTotal:0)))+Number(booking)}</td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
//   );
// };

// export default ConsolidateReport;

