import React, { useEffect, useState } from'react';
import  '../../Style/Home_Style/Home_Style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import PerfectScrollbar from 'perfect-scrollbar';
import { GiMoneyStack } from "react-icons/gi";
import { IoBarChartSharp } from "react-icons/io5";
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { MdMenuOpen } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import axios from 'axios';
import {api} from '../api';
import { GiReceiveMoney } from "react-icons/gi";
import '../../Style/Home_Style/BookingStyle.css'
import TripPlan from './TripPlan';


const Dashboard=()=>{
  const [dash, setDash] = useState({
  totalBooking: "",
  monthlyBooking: "",
  todayBooking: "",
  totalDue: "",
  monthlyDue: "",
  todayDue: "",
  totalExpenses: "",
  monthlyExpenses: "",
  todayExpenses: "",
  todayVendor: "",
  monthlyVendor:"",
  totalVendor:"",
});

const [dashData, setDashData] = useState([]);
const [fcVehicle,setFcVehicle]=useState([]);
const [fcRentVehicle,setFcRentVehicle]=useState([]);
const [insuranceRentVehicle,setInsuranceRentVehicle]=useState([]);
const [insuranceVehicle,setInsuranceVehicle]=useState([]);
const [polution,setPolution]=useState([]);
const [tripPlan,setTripPlan]=useState([]);

const fetchData = async () => {
  try {
    const response = await axios.get(`${api.api}/booking/dashboard`);
    const fc = await axios.get(`${api.api}/vehicle/fcVehicle`);
    const Rent = await axios.get(`${api.api}/vehicle/fcVehicleRent`);
    const booking=await axios.get(`${api.api}/booking/latestBookintReport`)
    setTripPlan(booking.data?.booking)
    setFcVehicle(fc.data.fc);
    setFcRentVehicle(Rent.data.fc);
    setInsuranceRentVehicle(Rent.data.insurance);
    setInsuranceVehicle(fc.data.insurance);
    setPolution(fc.data.pollution)
    setDashData(response.data);
    // console.log(fc.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// console.log(fcVehicle);

const updateValue = () => {
  setDash(prevDash => ({
    ...prevDash,
    totalBooking: dashData?.totalBooking?.[0]?.billTotal || "",
    monthlyBooking: dashData?.monthlyExpenses?.[0]?.monthlyExpenses || "",
    todayBooking: dashData?.todayBooking?.[0]?.totalBillAmount || "",
    totalDue: dashData?.totalDue?.[0]?.totalDue || "",
    monthlyDue: dashData?.monthlyDue?.[0]?.monthlyDue || "",
    todayDue: dashData?.todayDue?.[0]?.todayDue || "",
    totalExpenses: dashData?.totalExpenses?.[0]?.totalExpenses || "",
    monthlyExpenses: dashData?.monthlyExpenses?.[0]?.monthlyExpenses || "",
    todayExpenses: dashData?.todayExpenses?.[0]?.todayExpenses || "",
    todayVendor: dashData?.todayVendor?.[0]?.todayExpenses || "",
    monthlyVendor:dashData?.monthlyVendor?.[0]?.monthlyExpenses || "",
    totalVendor:dashData?.totalVendor?.[0]?.totalExpenses || "",
  }));

};

useEffect(() => {
  fetchData();
}, []);

useEffect(() => {
  updateValue();
}, [dashData]);

// console.log(dash);


    return(
        <>
           <div className="row">
                  <div className="col-xl-4 col-lg-6 stretch-card grid-margin">
                    <div className="card mdc-card info-card  info-card--danger">
                      <div className="card-inner">
                        <h5 className="card-title">Closing Balance</h5>
                        <h5 className="font-weight-light pb-2 mb-1 border-bottom">{ Number(Number(dash?.totalDue?dash?.totalDue:0) - (Number(dash?.totalExpenses?dash?.totalExpenses:0)+ Number(dash?.totalVendor?dash?.totalVendor:0)))}</h5>
                        {/* <p className="text-small text-muted">48% target reached</p> */}
                        <div className="card-icon-wrapper">
                          <i className="mdi "><IoBarChartSharp /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 stretch-card grid-margin">
                    <div className="card mdc-card info-card info-card--success">
                      <div className="card-inner">
                        <h5 className="card-title">Day  Income</h5>
                        <h5 className="font-weight-light pb-2 mb-1 border-bottom">{ Number(dash?.todayDue)}</h5>
                        {/* <p className="text-small text-muted">55% target reached</p> */}
                        <div className="card-icon-wrapper">
                          <i className="mdi "><GiMoneyStack/></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 stretch-card grid-margin">
                    <div className="card mdc-card info-card info-card--primary">
                      <div className="card-inner">
                        <h5 className="card-title">Day Expenses</h5>
                        <h5 className="font-weight-light pb-2 mb-1 border-bottom">{Number(dash?.todayExpenses?dash?.todayExpenses:0)+Number(dash?.monthlyVendor?dash?.monthlyVendor:0)}</h5>
                        {/* <p className="text-small text-muted">87% target reached</p> */}
                        <div className="card-icon-wrapper">
                          <i className="mdi "><BsFillFuelPumpDieselFill /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 stretch-card grid-margin">
                    <div className="card mdc-card info-card info-card--success">
                      <div className="card-inner">
                        <h5 className="card-title">Monthly Income</h5>
                        <h5 className="font-weight-light pb-2 mb-1 border-bottom">{ Number(dash?.monthlyDue?dash?.monthlyDue:0)}</h5>
                        {/* <p className="text-small text-muted">87% target reached</p> */}
                        <div className="card-icon-wrapper">
                          <i className="mdi "><GiReceiveMoney /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 stretch-card grid-margin">
                    <div className="card mdc-card info-card info-card--info">
                      <div className="card-inner">
                        <h5 className="card-title">Monthly Expenses</h5>
                        <h5 className="font-weight-light pb-2 mb-1 border-bottom">{Number(dash?.monthlyExpenses?dash?.monthlyExpenses:0)+Number(dash?.monthlyVendor?dash?.monthlyVendor:0)}</h5>
                        {/* <p className="text-small text-muted">87% target reached</p> */}
                        <div className="card-icon-wrapper">
                          <i className="mdi "><FaScrewdriverWrench /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <TripPlan/>
                {/* <div class="card-body pb-0 col-12"  >
                  <h5 class="card-title">Trip Planing <span>| Today</span></h5>

                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-bold">Booking Date</th>
                        <th scope="col" className="fw-bold">Customer Name</th>
                        <th scope="col" className="fw-bold">Customer Mobile No</th>
                        <th scope="col" className="fw-bold">Vehicle No</th>
                        <th scope="col" className="fw-bold">Vehicle Type</th>
                        <th scope="col" className="fw-bold">Pickup Location</th>
                        <th scope="col" className="fw-bold">Drop Location</th>
                        <th scope="col" className="fw-bold">Driver Name</th>
                        <th scope="col" className="fw-bold">Mobile No</th>
                        <th sscope="col" className="fw-bold">Bill Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                     {tripPlan.map((item,i)=>{ 
                      console.log(item)
                     return<tr>
                        <th scope="row"><a href="#">{new Date(item.booking_date).toLocaleDateString('en-GB')}</a></th>
                        <td  class="text-primary fw-bold">{item.customer.customer_name}</td>
                        <td  class="text-primary fw-bold">{item.customer.phone_number}</td>
                        <td class="fw-bold">{item.vehicle.vehicle_number}</td>
                        <td class="fw-bold">{item.vehicle.vehicle_type}</td>
                        <td>{item.pickup_location}</td>
                        <td>{item.dropoff_location}</td>
                        <td>{item.driver.driver_name}</td>
                        <td class="fw-bold">{item.driver.phone_number}</td>
                        <td class="fw-bold">Rs. {item.bill_amount}</td>
                      </tr> })}
                    </tbody>
                  </table>

                </div> */}

                <div className="row mt-5">
                    <div className="col-sm-4 stretch-card grid-margin">
                    <div className="card mdc-card bg-success text-dark">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h3 className="font-weight-normal">FC Vehicle List - Own<p>30 Days</p></h3>
                          <i className="mdi mdi-dots-vertical options-icon text-white"></i>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-success">
                              <h5 className="font-weight-normal mt-2">Vehicle No</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-success">
                              <h5 className="font-weight-normal mt-2 ">Date</h5>
                          </div>
                          {
                            fcVehicle.map((item,i)=>{
                              // console.log(item);
                             return <>
                          <div className="col-sm-6 stretch-card grid-margin" key={i}>
                              <h5 className="font-weight-normal">{item?.vehicle_number}</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin">
                              <h5 className="font-weight-normal ">{new Date(item?.fc).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // hour12: true,
          })}</h5>
                          </div>
                          </> })
                          }
                        </div>
                      </div>
                    </div>
                    </div>
                   <div className="col-sm-4 stretch-card grid-margin">
                    <div className="card mdc-card bg-info text-dark">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h3 className="font-weight-normal">Insurance Vehicle List - Own <p>30 Days</p></h3>
                          <i className="mdi mdi-dots-vertical options-icon text-white"></i>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-primary">
                            <div>
                              <h5 className="font-weight-normal mt-2 ">Vehicle No</h5>
                            </div>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-primary">
                          <div>
                              <h5 className="font-weight-normal mt-2">Date</h5>
                          </div>
                          </div>
                          {
                            insuranceVehicle.map((item,i)=>{
                              // console.log(item);
                             return <>
                          <div className="col-sm-6 stretch-card grid-margin" key={i}>
                              <h5 className="font-weight-normal">{item?.vehicle_number}</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin">
                              <h5 className="font-weight-normal ">{new Date(item?.insurance).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // hour12: true,
          })}</h5>
                          </div>
                          </> })
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 stretch-card grid-margin">
                    <div className="card mdc-card bg-warning text-dark">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h3 className="font-weight-normal ">Polution Vehicle List - Own <p>30 Days</p></h3>
                          <i className="mdi mdi-dots-vertical options-icon text-white"></i>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-dark">
                            <div>
                              <h5 className="font-weight-normal mt-2 ">Vehicle No</h5>
                            </div>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-dark">
                          <div>
                              <h5 className="font-weight-normal mt-2">Date</h5>
                          </div>
                          </div>
                          {
                            polution.map((item,i)=>{
                              // console.log(item);
                             return <>
                          <div className="col-sm-6 stretch-card grid-margin text-dark" key={i}>
                              <h5 className="font-weight-normal">{item?.vehicle_number}</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin text-dark">
                              <h5 className="font-weight-normal ">{new Date(item?.pollution).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // hour12: true,
          })}</h5>
                          </div>
                          </> })
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-4 stretch-card grid-margin">
                    <div className="card mdc-card bg-light text-black">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h3 className="font-weight-normal">FC Vehicle List - Rent<p>30 Days</p></h3>
                          <i className="mdi mdi-dots-vertical options-icon text-white"></i>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-success">
                              <h5 className="font-weight-normal mt-2">Vehicle No</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-success">
                              <h5 className="font-weight-normal mt-2 ">Date</h5>
                          </div>
                          {
                            fcRentVehicle.map(item=>{
                              // console.log(item);
                             return <>
                          <div className="col-sm-6 stretch-card grid-margin">
                              <h5 className="font-weight-normal">{item?.vehicle_number}</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin">
                              <h5 className="font-weight-normal ">{new Date(item?.fc).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // hour12: true,
          })}</h5>
                          </div>
                          </> })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 stretch-card grid-margin">
                    <div className="card mdc-card .bg-primary text-black">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h3 className="font-weight-normal">Insurance Vehicle List - Rent <p>30 Days</p></h3>
                          <i className="mdi mdi-dots-vertical options-icon text-white"></i>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-primary">
                            <div>
                              <h5 className="font-weight-normal mt-2 ">Vehicle No</h5>
                            </div>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin bg-white text-primary">
                          <div>
                              <h5 className="font-weight-normal mt-2">Date</h5>
                          </div>
                          </div>
                          {
                            insuranceRentVehicle.map(item=>{
                              // console.log(item);
                             return <>
                          <div className="col-sm-6 stretch-card grid-margin">
                              <h5 className="font-weight-normal">{item?.vehicle_number}</h5>
                          </div>
                          <div className="col-sm-6 stretch-card grid-margin">
                              <h5 className="font-weight-normal ">{new Date(item?.insurance).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // hour12: true,
          })}</h5>
                          </div>
                          </> })
                          }
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

           
        </>
    )
}

export default Dashboard;