// import logo from './logo.svg';
// import React, { useEffect, useState } from'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home/Home';
import Login from './Components/Login_Page/Login';
import CustomerCreation from './Components/Forms/CustomerCreation';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

function App() {


  return (
    <div className="taxinow">
     <BrowserRouter>
     <Routes>
     <Route path='/home' element={<Home/>}/>
      <Route path='/home/*' element={<Home/>}/>
      <Route path='/login/*' element={<Login/>}/>
      <Route path='/' element={<Login/>}/>
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
